import qs from 'qs'

import { signInOTP } from '../../../services'

export const useConfirmEmailAddress = (setIsShowLoading, EmailId, searchProps, history, setEmail, setEmailId) => {
  const onSubmit = async (event) => {
    event.preventDefault()
    setIsShowLoading(true)

    const queryStringParameters = qs.parse(searchProps, { ignoreQueryPrefix: true })
    const params = {
      email: EmailId,
      partner: queryStringParameters.parceiro,
      campaign: queryStringParameters.campanha,
      isISA: queryStringParameters.isa === 'true',
    }

    const { error } = await signInOTP(params)

    if (!error) {
      history.push({
        pathname: '/verificacao',
        search: searchProps,
      })
    } else {
      setEmail('')
      setEmailId(null)
      history.push({
        pathname: '/cpf-nao-encontrado',
        search: searchProps,
      })
    }

    setIsShowLoading(false)
  }

  return [onSubmit]
}
