import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import { Person } from '@material-ui/icons'
import { TextErrorInput, Button, showToast } from '@provi/provi-components'
import { ArrowRight } from '../../assets/svg/arrow-right'
import { signIn, signInOTP } from '../../services'
import { ContainerText, Footer, SubTitle, Form, ContainerBack } from '../style'
import { hasMicrosoftDomain, transformObjectIntoQueryParams } from '../../utils'

const WrapperForm = (props) => {
  const { values, errors, handleChange, handleSubmit } = props

  return (
    <>
      <ContainerBack onClick={() => props.setScreen('introduction')}>
        <ArrowRight color="white" />
      </ContainerBack>
      <ContainerText>
        <SubTitle>Escreva abaixo o email que você usou no primeiro cadastro:</SubTitle>
      </ContainerText>
      <Form onSubmit={handleSubmit}>
        <FormControl>
          <Input
            placeholder="Escreva aqui seu email"
            inputProps={{ style: { color: 'white' } }}
            value={values.email}
            type="email"
            onChange={handleChange('email')}
            startAdornment={
              <InputAdornment position="start">
                <Person style={{ color: 'white' }} />
              </InputAdornment>
            }
          />
          {errors.email && <TextErrorInput text={errors.email} />}
        </FormControl>
      </Form>
      <Footer>
        <Button text="Avançar" onClick={handleSubmit} />
      </Footer>
    </>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: (props) => ({ email: props.emailLocal || '' }),
  validationSchema: () => {
    const schema = {
      email: Yup.string().email('Digite um email válido').required('Preencha o campo Email'),
    }
    return Yup.object().shape(schema)
  },
  handleSubmit: async ({ email }, { props }) => {
    props.setEmail(email)
    props.setIsShowLoading(true)
    props.setTypeModal('dontReceiveEmail')

    const partner = props.partner
    const campaign = props.campanha
    const isISA = props.isISA
    const resend = true

    try {
      if (!hasMicrosoftDomain(email)) {
        await signIn({ email, partner, campaign, resend, isISA })
        props.setScreen('sendEmail')
      } else {
        const { error } = await signInOTP({ email, campaignSlug: campaign, isISA, partnerSlug: partner })
        const searchParams = transformObjectIntoQueryParams({ parceiro: partner, email, isa: isISA, campanha: campaign })

        if (!error) {
          props.history.push({
            pathname: '/verificacao',
            search: searchParams,
          })
        } else {
          props.history.push({
            pathname: '/email-nao-encontrado',
            search: searchParams,
          })
        }
      }
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.error.message.split(' ')[0]
        if (['Remova', 'Não'].includes(errorMessage) || err.response.data.error.message.includes('ISA')) {
          showToast(err.response.data.error.message || 'Ocorreu um erro inesperado')
          return false
        }
      }

      if (!hasMicrosoftDomain(email)) {
        props.setScreen('searchSignup')
      } else {
        props.history.push({
          pathname: '/email-nao-encontrado',
          search: transformObjectIntoQueryParams({ parceiro: partner, email, isa: isISA, campanha: campaign }),
        })
      }
    }
    props.setIsShowLoading(false)
  },
})(WrapperForm)
