import React from 'react'
import { Route, Router, Switch, Redirect } from 'react-router-dom'

import { ToastComponent } from '@provi/provi-components'
import { ROUTES } from './routes'
import { history } from './history'

export const Navigation = () => {
  const pathname = history.location.pathname
  const query = history.location.search

  return (
    <Router history={history}>
      <Switch>
        {/* remove all trailing slashes */}
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1) + query} />
        {ROUTES.map((item) => (
          <Route key={item.path} path={item.path} exact={true} component={item.component} />
        ))}
      </Switch>
      <ToastComponent />
    </Router>
  )
}
