import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import { Person } from '@material-ui/icons'
import { TextErrorInput, Button, InputMasks } from '@provi/provi-components'
import { isValidCpf } from '../../utils'
import { signInCpf } from '../../services'
import { Form, SubTitle, Bold } from '../style'

const WrapperFormik = (props) => {
  const { values, errors, handleChange, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <FormControl>
        <SubTitle>Não encontramos nenhum cadastro vinculado a esse email.</SubTitle>
        <Bold>Insira o seu CPF para buscarmos o seu cadastro.</Bold>
        <InputMasks
          placeholder={'Ex: 111.222.333-44'}
          inputProps={{ style: { color: 'white' } }}
          value={values.cpf}
          mask="999.999.999-99"
          margin="normal"
          id="cpf"
          type="tel"
          onChange={handleChange('cpf')}
          startAdornment={
            <InputAdornment position="start">
              <Person style={{ color: 'white' }} />
            </InputAdornment>
          }
        />
        {errors.cpf && <TextErrorInput text={errors.cpf} />}
      </FormControl>
      <Button
        text="Avançar"
        backgroundDisabled
        disabled={!(values.cpf && values.cpf.replace(/[^\d]+/g, '').length === 11)}
        type="submit"
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  mapPropsToValues: () => ({ cpf: '' }),
  validationSchema: Yup.object().shape({
    cpf: Yup.string()
      .required('Preencha o seu CPF')
      .test({
        name: 'cpf',
        message: 'CPF inválido',
        test: (cpf) => cpf && isValidCpf(cpf.replace(/[^\d]+/g, '')),
      }),
  }),
  handleSubmit: async ({ cpf }, { props }) => {
    props.setIsShowLoading(true)
    props.setTypeModal('wrong')
    let partner = props.partner
    let campaign = props.campanha
    try {
      const { data } = await signInCpf({ cpf: cpf.replace(/[^\d]+/g, ''), partner, campaign })
      props.setEmail(data.email)
      props.setScreen('sendEmail')
    } catch (err) {
      console.log(err)
      if (partner) {
        props.setScreen('emailNotFound')
      } else {
        props.setScreen('cridNotFound')
      }
    }
    props.setIsShowLoading(false)
  },
})(WrapperFormik)
