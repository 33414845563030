import { Authentication } from '../screens'
import SignIn from '../screens/OTP/SignIn'
import VerificationCode from '../screens/OTP/VerificationCode'
import EmailNotFound from '../screens/OTP/EmailNotFound'
import ConfirmEmailAddress from '../screens/OTP/ConfirmEmailAddress'
import CPFNotFound from '../screens/OTP/CPFNotFound'
import LoginSanar from '../screens/auth-sanar/login-sanar'
import SendEmailSanar from '../screens/auth-sanar/send-email-sanar'
import HaveAccountSanar from '../screens/auth-sanar/have-account-sanar'
import EmailNotFoundSanar from '../screens/auth-sanar/email-not-found-sanar'
import OtpSanar from '../screens/auth-sanar/otp-sanar'
import AlreadyHasContract from '../screens/auth-sanar/already-has-contract'

export const ROUTES = [
  {
    path: '/entrar',
    component: SignIn,
  },
  {
    path: '/verificacao',
    component: VerificationCode,
  },
  {
    path: '/email-nao-encontrado',
    component: EmailNotFound,
  },
  {
    path: '/cpf-nao-encontrado',
    component: CPFNotFound,
  },
  {
    path: '/confirmacao-email',
    component: ConfirmEmailAddress,
  },
  {
    path: '/',
    component: Authentication,
  },
  {
    path: '/:partner',
    component: Authentication,
  },
  {
    path: '/isa/:partner',
    component: Authentication,
  },
  {
    path: '/:partner/campanha/:campanha',
    component: Authentication,
  },
  {
    path: '/intro/sanar',
    component: Authentication,
  },
  {
    path: '/login/:slugSanar',
    component: LoginSanar,
  },
  {
    path: '/login/:slugSanar/send-email',
    component: SendEmailSanar,
  },
  {
    path: '/login/:slugSanar/have-account',
    component: HaveAccountSanar,
  },
  {
    path: '/login/:slugSanar/email-not-found',
    component: EmailNotFoundSanar,
  },
  {
    path: '/login/:slugSanar/otp',
    component: OtpSanar,
  },
  {
    path: '/login/:slugSanar/have-contract',
    component: AlreadyHasContract,
  },
]
