import React from 'react'

export const Question = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C4.9 0 0 4.9 0 11C0 17.1 4.9 22 11 22C17.1 22 22 17.1 22 11C22 4.9 17.1 0 11 0ZM11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20Z"
      fill="#DE0C4B"
    />
    <path
      d="M12.2 5.20003C10.1 4.50003 7.79999 5.60003 7.09999 7.60003C6.99999 8.20003 7.2 8.80003 7.8 8.90003C8.3 9.10003 8.79999 8.90003 8.99999 8.30003C9.39999 7.30003 10.5 6.70003 11.6 7.10003C12.4 7.40003 12.9 8.10003 12.9 9.00003C12.9 10 11.2 10.8 10.6 11.1C10.5 11.1 10.5 11.2 10.4 11.2L10.3 11.3C10.3 11.3 10.2 11.4 10.2 11.5C10.2 11.6 10.1 11.6 10.1 11.7C10.1 11.8 10.1 11.8 10.1 11.9C10.1 12 10.1 12 10.1 12.1V13.1C10.1 13.7 10.5 14.1 11.1 14.1C11.7 14.1 12.1 13.7 12.1 13.1V12.8C13.2 12.3 15.1 11.1 15.1 9.10003C14.9 7.30003 13.8 5.80003 12.2 5.20003Z"
      fill="#DE0C4B"
    />
    <path d="M11 15C10.4 15 10 15.4 10 16C10 16.6 10.5 17 11 17C11.5 17 12 16.6 12 16C12 15.4 11.6 15 11 15Z" fill="#DE0C4B" />
  </svg>
)
