import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import background_login_desktop from '../../assets/svg/background-desktop-login.svg'

export const ContainerLogin = styled.div`
  width: 65%;
  height: 100vh;
  background-color: ${({ color = '#004466' }) => color};
  display: flex;
  flex-direction: column;
  justify-content: ${({ isMobile = false }) => (isMobile ? 'top' : 'center')};
  margin-top: ${({ isMobile = false, hasHeader = false }) => (isMobile ? (hasHeader ? '164px' : '100px') : '0px')};
  align-items: center;

  ${theme.breakpoints.down('md')} {
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
  }
`

export const Container = styled.div`
  position: relative;
  width: 50%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerBackground = styled.div`
  width: 35%;
  background: url(${background_login_desktop});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100%;
`

export const ContainerText = styled.div``

export const Title = styled.h4`
  font-size: 48px;
  color: ${theme.colors.pink900};
  margin: 20px 0;
  font-family: CenturyGothicBold, sans serif;

  ${theme.breakpoints.down('md')} {
    font-size: 24px;
  }
`

export const SubTitle = styled.p`
  color: ${theme.colors.white100};
  font-size: 20px;
  line-height: 30px;
  font-family: CenturyGothic, sans serif;
  ${({ margin }) => margin && 'margin: 0'};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-family: CenturyGothicBold, sans serif;
  color: ${theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 20px 0;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    align-items: center;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ color = '#004466' }) => color};
  position: relative;
`

export const Form = styled.form`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 34px;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
    .MuiInput-root {
      margin-bottom: 8px;
    }
    &:after {
      border-bottom: 2px solid #ffffff;
    }
  }
`

export const TextLinkExternal = styled.a`
  line-height: 2;
  text-align: left;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`

export const TextTerms = styled.p`
  line-height: 15px;
  ${({ bold }) => bold && 'font-family: CenturyGothicBold, sans-serif;'}
  font-size: 9px;
  color: ${theme.colors.white100};
  margin: 0;
  text-align: center;
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`

export const Bold = styled.span`
  font-family: CenturyGothicBold, sans serif;
  color: ${theme.colors.white100};
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 16px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`
