import React, { useContext } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import { TextErrorInput, Button, theme } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { ContainerBack } from '../../../components/container-back'
import { SubTitle } from '../../../components/subtitle'
import { Form } from '../../../components/form'
import { Footer } from '../../../components/footer'
import { ArrowRight } from '../../../assets/svg/arrow-right'
import { useCPFNotFound } from './hooks'

function SignIn({ history, location: { search: searchProps } }) {
  const { setIsShowModal, setTypeModal, setIsShowLoading, email, setEmail } = useContext(TemplateContext)
  const [onChange, onSubmit, error] = useCPFNotFound(setIsShowLoading, email, setEmail, searchProps, history)

  return (
    <Template>
      <ContainerBack
        onClick={() =>
          history.push({
            pathname: '/email-nao-encontrado',
            search: searchProps,
          })
        }
      >
        <ArrowRight color="white" />
      </ContainerBack>
      <div>
        <SubTitle bold>Não encontramos nenhum email vinculado a esse CPF.</SubTitle>
        <SubTitle>Comece uma solicitação agora:</SubTitle>
      </div>
      <Form onSubmit={onSubmit}>
        <FormControl>
          <Input
            autoFocus
            placeholder="Digite aqui o seu melhor email"
            inputProps={{ style: { color: 'white' }, type: 'email' }}
            value={email}
            onChange={onChange}
            error={!!error}
          />
          {error && <TextErrorInput text={error} />}
        </FormControl>
      </Form>
      <Footer>
        <Button
          text="Avançar"
          onClick={onSubmit}
          disabled={!!error || !email}
          backgroundDisabled={!!error || !email}
          textLink={{
            text: 'Algo está errado?',
            color: theme.colors.white100,
            onClick: () => {
              setTypeModal('wrong')
              setIsShowModal(true)
            },
          }}
        />
      </Footer>
    </Template>
  )
}

export default SignIn
