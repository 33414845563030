import React, { useState } from 'react'
import { URL_EXTERNAL } from '../../constants'
import { TemplateContext } from './context'
import { useScreenMeasure, CheckISA } from './hooks'
import { trackEvent } from '../google-analytics'

export const TemplateProvider = (props) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [partner, setPartner] = useState()
  const [typeSign, setTypeSign] = useState()
  const [typeModal, setTypeModal] = useState('dontReceiveEmail')
  const [isShowLoading, setIsShowLoading] = useState(false)
  const [screen, setScreen] = useState('introduction')
  const [partnerFullName, setPartnerFullName] = useState('')
  const [email, setEmail] = useState()
  const [EmailId, setEmailId] = useState()
  const [isMobile] = useScreenMeasure()
  const [isISA] = CheckISA()

  const modals = {
    dontReceiveEmail: {
      title: 'Ainda não recebeu o seu email?',
      texts: [
        'Caso ainda não tenha recebido o seu email, não se preocupe! O email pode demorar até 30 minutos para chegar, ou também pode ter caído na caixa de Spam.',
        'Se não for o caso, clique no botão abaixo:',
      ],
      textButton: 'Não recebi',
      funcButton: () => {
        setScreen('veficationEmail')
        setIsShowModal(false)
        trackEvent({ category: 'Login or Signup error', action: 'Email not received', label: 'Email' })
      },
      textOpen: 'Abrir chat',
    },
    wrong: {
      title: 'Algo errado?',
      texts: [
        'Se você tem certeza que o seu melhor email já foi cadastrado, entre em contato com a gente que vamos te ajudar :)',
      ],
      textButton: 'Abrir chat',
      funcButton: () => window.open(URL_EXTERNAL, '_blank'),
    },
    havingProblems: {
      title: 'Está tendo problemas?',
      texts: [
        'Se você não está recebendo o email com o código de 6 dígitos, verifique se o seu email está escrito corretamente.',
        'Se o seu email estiver correto e mesmo assim você não recebeu o código, entre em contato com o time Provi que vamos te ajudar.',
      ],
      textButton: 'Entendi',
      funcButton: () => {
        setIsShowModal(false)
      },
      textOpen: 'Abrir chat',
    },
  }

  const value = {
    isMobile,
    isShowModal,
    setIsShowModal,
    typeSign,
    setTypeSign,
    typeModal,
    setTypeModal,
    isShowLoading,
    setIsShowLoading,
    screen,
    setScreen,
    email,
    setEmail,
    modals,
    partner,
    setPartner,
    isISA,
    EmailId,
    setEmailId,
    partnerFullName,
    setPartnerFullName,
  }

  return <TemplateContext.Provider value={value}>{props.children}</TemplateContext.Provider>
}
