import React, { useContext } from 'react'
import { FormControl, Input } from '@material-ui/core'
import { theme, TextErrorInput, Button, showToast } from '@provi/provi-components'
import qs from 'qs'

import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { Header } from '../../../components/header'
import { ContainerBack } from '../../../components/container-back'
import { ContainerQuestion } from '../../../components/container-question'
import { SubTitle } from '../../../components/subtitle'
import { Form } from '../../../components/form'
import { Footer } from '../../../components/footer'
import { ArrowRight } from '../../../assets/svg/arrow-right'
import { Question } from '../../../assets/svg/question'
import { useVerificationCode } from './hooks'

function VerificationCode({ history, location: { search: searchProps } }) {
  const { setIsShowModal, setTypeModal, setIsShowLoading, email, EmailId, setEmail } = useContext(TemplateContext)
  const [verificationCode, onChange, onSubmit, resendVerificationCode, error] = useVerificationCode(
    setIsShowLoading,
    showToast,
    searchProps,
    EmailId,
    email,
    setEmail
  )

  const previousUrl = () => {
    const queryStringParameters = qs.parse(searchProps, { ignoreQueryPrefix: true })

    if (queryStringParameters.isISA === 'true') {
      return `/isa/${queryStringParameters.parceiro}`
    } else if (queryStringParameters.campanha) {
      return `/${queryStringParameters.parceiro}/campanha/${queryStringParameters.campanha}`
    } else if (queryStringParameters.parceiro) {
      return `/${queryStringParameters.parceiro}`
    } else {
      return '/'
    }
  }

  return (
    <>
      <Header />
      <Template color={theme.colors.white100} screenIsClear hasHeader>
        <ContainerBack onClick={() => history.push(previousUrl())}>
          <ArrowRight color={theme.colors.blue900} />
        </ContainerBack>

        <ContainerQuestion
          onClick={() => {
            setTypeModal('havingProblems')
            setIsShowModal(true)
          }}
        >
          <Question />
        </ContainerQuestion>
        <div>
          <SubTitle color={theme.colors.blue900}>
            Enviamos um <strong>código de 6 números</strong> para o email <strong>{email}</strong>
          </SubTitle>
          <SubTitle bold color={theme.colors.blue900}>
            Digite o código abaixo:
          </SubTitle>
        </div>

        <Form onSubmit={onSubmit} isDark>
          <FormControl>
            <Input
              autoFocus
              placeholder="Ex: 12345"
              inputProps={{
                type: 'text',
                pattern: '[0-9]*',
                inputmode: 'numeric',
                maxlength: 6,
              }}
              value={verificationCode}
              onChange={onChange}
              error={!!error}
            />
            {error && <TextErrorInput text={error} />}
          </FormControl>
        </Form>
        <Footer>
          <Button
            text="Avançar"
            onClick={onSubmit}
            disabled={!!error || !verificationCode}
            textLink={{
              text: 'Reenviar código',
              color: theme.colors.blue900,
              onClick: resendVerificationCode,
            }}
          />
        </Footer>
      </Template>
    </>
  )
}

export default VerificationCode
