import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Form = styled.form`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 34px;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid ${({ isDark }) => (isDark ? theme.colors.blue900 : theme.colors.white100)};
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid ${({ isDark }) => (isDark ? theme.colors.blue900 : theme.colors.white100)};
  }

  .MuiFormControl-root {
    width: 100%;
    .MuiInput-root {
      margin-bottom: 8px;
    }
    &:after {
      border-bottom: 2px solid ${({ isDark }) => (isDark ? theme.colors.blue900 : theme.colors.white100)};
    }
  }
`
