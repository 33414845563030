import React from 'react'

import { DarkSideBarIllustration } from '../../assets/svg/dark-side-bar-illustration'
import { DarkSideIllustrationWrapper } from './styles'

export const DarkSideIllustration = () => {
  return (
    <DarkSideIllustrationWrapper>
      <DarkSideBarIllustration />
    </DarkSideIllustrationWrapper>
  )
}
