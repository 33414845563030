import React from 'react'
import { Modal, showToast } from '@provi/provi-components'

import { signIn } from '../../../services'

import { ModalTitle, TokenEmailsContainer, TokenEmail } from './style'

export const TokenEmailsModal = ({ cookieTokens, setShowModal, setIsShowLoading, partner, isISA }) => {
  const signInUserWithExistingToken = async ({ email, token }) => {
    try {
      setIsShowLoading(true)
      const {
        data: { accessUrl },
      } = await signIn({ email, partner, isISA, previouslyLogged: true, previouslyLoggedUserToken: token })
      if (!accessUrl) throw new Error('Parece que algo não funcionou como o devido. tente digitar o email escolhido!')
      window.location.href = accessUrl
    } catch (error) {
      showToast(error.message || 'Houve algum problema para realizar o login. Tente digitar o email escolhido!')
      setShowModal(false)
      setIsShowLoading(false)
    }
  }

  return (
    <Modal containerStyle={{ overflow: 'hidden' }} onDismiss={() => setShowModal(false)}>
      <ModalTitle>Você pode selecionar um dos e-mails abaixo para acessar o painel:</ModalTitle>

      <TokenEmailsContainer>
        {cookieTokens &&
          !!cookieTokens.length &&
          cookieTokens.map((item) => (
            <TokenEmail onClick={() => signInUserWithExistingToken({ email: item.email, token: item.token })} key={item.token}>
              {item.email}
            </TokenEmail>
          ))}
      </TokenEmailsContainer>
    </Modal>
  )
}
