import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { history } from '../../navigation/history'

import { TemplateContext } from '../../components/template/context'
import { FormFormik } from './form'

export const SignIn = (props) => {
  const { partner, campanha } = useParams()
  const [emailLocal, setEmailLocal] = useState(null)
  const { setEmail, setIsShowLoading, setTypeModal, setScreen, isISA } = useContext(TemplateContext)
  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    const email = params.get('email')
    setEmail(email)
    setEmailLocal(email)
  }, [setEmail])

  const propsWrapper = { emailLocal, setEmail, setIsShowLoading, setTypeModal, setScreen, partner, campanha, isISA, ...props }
  return <FormFormik {...propsWrapper} />
}
