import { useEffect } from 'react'
import { history } from '../../navigation/history'

export const useRouteValidation = (setPartnerSlug) => {
  useEffect(() => {
    const slugUrl = window.location.pathname.split('/')[2]
    if (!['sanar', 'sanarup-financiamento'].includes(slugUrl)) {
      history.push(`/${slugUrl}`)
    }
    if (typeof setPartnerSlug === 'function') {
      setPartnerSlug(slugUrl)
    }
  }, [setPartnerSlug])
}
