import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SubTitle = styled.p`
  color: ${({ color = theme.colors.white100 }) => color};
  font-size: 20px;
  line-height: 30px;
  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold' : 'CenturyGothic')}, sans serif;
  ${({ margin }) => margin && 'margin: 0'};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`
