const { REACT_APP_API_ENV, REACT_APP_SMARTLOOK_PROJECT } = process.env

export const TOKEN_FRONT_END = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjEsImVtYWlsIjoiam9hb0Bwcm92aS5jb20uYnIiLCJleHAiOiIzNmQifQ.yBOqt3uFDYs7byxW4ZczfOzxlUPgC8CZcWhlhPKBtn4`

export const URLS = {
  production: 'https://ms-passwordless.provi.com.br',
  staging: 'https://ms-passwordless-staging.provi.com.br',
}

export const SMARTLOOK_PROJECT = REACT_APP_SMARTLOOK_PROJECT

export const setEnvironment = (environment) => {
  switch (environment) {
    case 'production':
      return URLS.production
    case 'staging':
    default:
      return URLS.staging
  }
}
let _ROOT_URL = setEnvironment(REACT_APP_API_ENV)
console.log('_ROOT_URL: ', _ROOT_URL)

export const ROOT_URL = _ROOT_URL

export const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

const googleAnalyticsTrackingId = {
  production: 'UA-146320363-13',
  staging: 'UA-146320363-9',
}

const sdkDomain = {
  production: 'web.provi.com.br/',
  staging: 'web-staging.provi.com.br/',
}
export const trackingId = googleAnalyticsTrackingId[REACT_APP_API_ENV]
export const sdkBaseURL = `https://${sdkDomain[REACT_APP_API_ENV]}`

export const MesadaProviSlug = 'impulso-provi'

export const alreadyHasContractMessage =
  'Identificamos que você já possui uma mesada em andamento. Só será possível realizar uma nova solicitação após a conclusão do pagamento.'
