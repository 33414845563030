import React, { useContext } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { TextLink } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { SubTitle, Bold } from '../style'

export const ResendingEmail = () => {
  const { typeModal, modals, setIsShowModal, email } = useContext(TemplateContext)
  return (
    <FormControl>
      <SubTitle>
        Enviamos um link para o email <Bold>{email}</Bold>
      </SubTitle>
      <SubTitle>Esse link é o seu acesso para a nossa plataforma.</SubTitle>
      <TextLink text={modals[typeModal].textOpen} color="white" onClick={() => setIsShowModal(true)} />
    </FormControl>
  )
}
