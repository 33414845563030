import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { ToastContainer } from 'react-toastify'

import { TemplateProvider } from './components/template/provider'
import { SanarProvider } from './components/template-sanar/provider'
import ErrorBoundary from './components/error-boundary'
import { Navigation } from './navigation'

import './config'
import './style.css'

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <TemplateProvider>
          <SanarProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Navigation />
          </SanarProvider>
        </TemplateProvider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('provi-root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./navigation', () => {
    render()
  })
}
