import React from 'react'
import { CloseIcon } from '../../assets/svg/CloseIcon'
import * as S from './styles'

export const ModalSanar = ({ title, onDismiss, children, width = '600px' }) => {
  return (
    <S.Overlay>
      <S.Container width={width}>
        <S.ContainerTitle>
          <S.Title>{title}</S.Title>
          <S.ButtonClose id="close" onClick={onDismiss}>
            <CloseIcon />
          </S.ButtonClose>
        </S.ContainerTitle>
        <S.Content>
          <S.Body>{children}</S.Body>
        </S.Content>
      </S.Container>
    </S.Overlay>
  )
}
