import React from 'react'

import { LogoSanarBranch } from '../../../assets/svg/LogoSanarBranco'
import { MadeByProviBranco } from '../../../assets/svg/MadeByProviBranco'
import { ProviLogo } from '../../../assets/svg/ProviLogo'
import { SanarLogo } from '../../../assets/svg/SanarLogo'
import Button from '../../../components/Button'

import * as S from './styles'

const AlreadyHasContract = () => {
  return (
    <S.Container>
      <S.Header>
        <LogoSanarBranch /> <MadeByProviBranco />
      </S.Header>

      <S.MobileHeader>
        <SanarLogo />
        <ProviLogo />
      </S.MobileHeader>

      <S.Wrapper>
        <S.Title>Você já recebe uma Mesada</S.Title>
        <S.Subtitle>
          Não é possível contratar duas Mesadas ao mesmo tempo. Para fazer um novo pedido, é necessário quitar todas as parcelas
          do financiamento que você já fez.
        </S.Subtitle>

        <Button
          footerButton={true}
          text="Acessar Painel do aluno"
          onClick={() => window.location.replace('https://login.principia.net')}
        />
      </S.Wrapper>
    </S.Container>
  )
}

export default AlreadyHasContract
