import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ModalTitle = styled.div`
  font-family: CenturyGothicBold, sans serif;
  color: ${theme.colors.blue900};
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 8px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TokenEmailsContainer = styled.div`
  max-height: 312px;
  overflow: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const TokenEmail = styled.div`
  display: flex;
  font-family: CenturyGothic, sans serif;
  width: 90%;
  height: 48px;
  border-bottom: 1px solid ${theme.colors.grey600};
  align-items: center;
  padding: 8px;

  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.grey600};
  }

  transition: background-color 0.5s;
`
