import styled from 'styled-components'

import { theme } from '@provi/provi-components'
import LogoSanar from '../../../assets/images/background_sanar_v2.png'

export const Container = styled.div`
  font-family: 'Montserrat', sans serif;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: url(${LogoSanar});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 70%;
  background-size: 60%;

  ${theme.breakpoints.down('md')} {
    background: #ffffff;
  }
`

export const Header = styled.header`
  max-width: 100vw;
  padding: 80px 72px;

  ${theme.breakpoints.down('sm')} {
    padding: 40px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;

  margin-top: 30px;

  row-gap: 24px;

  padding-left: 72px;

  ${theme.breakpoints.down('sm')} {
    padding: 24px;
  }
`

export const ContainerLogo = styled.span`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  ${theme.breakpoints.down('sm')} {
    display: inline;
  }
`

export const ContainerLogoProvi = styled.span`
  margin-top: 60px;
`

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;

  margin: 0;
`
