import { isString } from 'lodash'
import qs from 'qs'

import { history } from '../navigation/history'

export const setLocalstorage = (storage, name) => {
  if (storage && name) localStorage.setItem(name, removeSpecialCharacters(storage))
}

export const removeSpecialCharacters = (element) => {
  if (!isString(element)) return element

  return element.replace(/[^\d]+/g, '')
}

export const isValidCpf = (value) => {
  if (!value) return false
  let sum = 0
  let rest
  let cpf = value.replace(/[^\d]+/g, '')
  if (cpf.length !== 11) return false
  if (cpf === '00000000000') return false
  if (cpf === '11111111111') return false
  if (cpf === '22222222222') return false
  if (cpf === '33333333333') return false
  if (cpf === '44444444444') return false
  if (cpf === '55555555555') return false
  if (cpf === '66666666666') return false
  if (cpf === '77777777777') return false
  if (cpf === '88888888888') return false
  if (cpf === '99999999999') return false
  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false
  return true
}

export const getSlugPartner = () => {
  const pathname = history.location.pathname
  const split = pathname.split('/')

  if (split.length === 2 || split.length === 3) {
    return split[split.length - 1]
  } else if (split.length > 3) {
    return split[split.length - 3]
  } else {
    return null
  }
}

export const getCampaign = () => {
  const pathname = history.location.pathname
  const split = pathname.split('/')
  if (split.length === 2) {
    return false
  }
  return split[split.length - 1]
}

/**
 * @description Check if the email address given has a Microsoft domain to fork authentication flow
 * @function
 * @name hasMicrosoftDomain
 * @param {string} email
 * @returns {boolean}
 */
export const hasMicrosoftDomain = (email) => {
  return ['@outlook', '@hotmail', '@msn', '@live', '@passport'].some((domain) => email.includes(domain))
}

/**
 * @description Transform any object into query params
 * @function
 * @name transformObjectIntoQueryParams
 * @param {object} data
 * @returns {string}
 */
export const transformObjectIntoQueryParams = (data) => {
  return qs.stringify(data, { addQueryPrefix: true })
}
