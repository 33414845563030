import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { TemplateContext } from '../../components/template/context'
import { FormHaveAccount } from './form'

export const HaveAccount = (props) => {
  let { partner, campanha } = useParams()
  const { setEmail, setTypeModal, setIsShowLoading, setScreen, typeSign, isISA } = useContext(TemplateContext)
  return (
    <FormHaveAccount
      typeSign={typeSign}
      setEmail={setEmail}
      setTypeModal={setTypeModal}
      setScreen={setScreen}
      setIsShowLoading={setIsShowLoading}
      partner={partner}
      campanha={campanha}
      isISA={isISA}
      {...props}
    />
  )
}
