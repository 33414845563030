import React, { useContext } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import { TextErrorInput, Button } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { ContainerBack } from '../../../components/container-back'
import { SubTitle } from '../../../components/subtitle'
import { Form } from '../../../components/form'
import { Footer } from '../../../components/footer'
import { ArrowRight } from '../../../assets/svg/arrow-right'
import { useSignIn } from './hooks'

function SignIn({ history }) {
  const { email, setEmail, setIsShowLoading } = useContext(TemplateContext)
  const [onChange, onSubmit, error] = useSignIn(setIsShowLoading, setEmail)

  return (
    <Template>
      <ContainerBack onClick={() => history.push('/')}>
        <ArrowRight color="white" />
      </ContainerBack>
      <div>
        <SubTitle bold>Escreva abaixo o email que você usou no primeiro cadastro:</SubTitle>
      </div>
      <Form onSubmit={onSubmit}>
        <FormControl>
          <Input
            autoFocus
            placeholder="Escreva aqui o seu email"
            inputProps={{ style: { color: 'white' }, type: 'email' }}
            value={email}
            onChange={onChange}
            error={!!error}
          />
          {error && <TextErrorInput text={error} />}
        </FormControl>
      </Form>
      <Footer>
        <Button text="Avançar" type="Submit" disabled={!!error || !email} backgroundDisabled={!!error || !email} />
      </Footer>
    </Template>
  )
}

export default SignIn
