import React from 'react'
import { Wrapper, Screen, Wrapper503, WrapperErrorText, BoldText, Text, WrapperIcon } from './style'

import { Text503 } from './svg/503'
import { ErrorText } from './svg/ErrorText'
import { ProviIcon } from './svg/ProviIcon'

export const Background = () => {
  return (
    <Wrapper>
      <Screen>
        <Wrapper503>
          <Text503 size="md" />
        </Wrapper503>
        <WrapperErrorText>
          <ErrorText />

          <BoldText>Desculpe!</BoldText>
          <Text>Alguma coisa deu errado por nossa culpa.</Text>
          <WrapperIcon>
            <ProviIcon />
          </WrapperIcon>
        </WrapperErrorText>
      </Screen>
    </Wrapper>
  )
}
