import React, { useContext } from 'react'

import * as S from './styles'
import { SanarLogo } from '../../../assets/svg/SanarLogo'
import { ProviLogo } from '../../../assets/svg/ProviLogo'
import { InputFieldV3, LoadingProvi } from '@provi/provi-components'
import Button from '../../../components/Button'
import { MadePossibleProvi } from '../../../assets/svg/MadePossibleProvi'
import { useAuthSanar } from './hooks'
import { TemplateSanarContext } from '../../../components/template-sanar/context'

const LoginSanar = () => {
  const { isMobile, email, setEmail, setIsShowLoading, isShowLoading } = useContext(TemplateSanarContext)
  const [handleSubmit, partnerSlug] = useAuthSanar({ email, setIsShowLoading })

  return (
    <S.Container>
      {isShowLoading && <LoadingProvi />}
      <S.Header>
        <SanarLogo />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.Title>
          {/* {partnerSlug === 'sanar' ? (
            <>
              A <strong>Mesada</strong>
            </>
          ) : (
            <>
              O <strong>Financiamento</strong>
            </>
          )}{' '}
          que vai <br />
          mudar seu internato! */}
          Solicitação temporariamente indisponível! <br /><br />
          Estamos passando por algumas mudanças 
          e neste momento você não consegue contratar a Mesada. <br /><br />
          Estamos trabalhando para que isso seja regularizado em breve!
        </S.Title>
        {/* <InputFieldV3
          placeholder="cecilia@email.com.br"
          label="Digite o seu e-mail"
          isRequired={true}
          width="370px"
          mobileWidth="370px"
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        /> */}
        {/* <Button footerButton text="Entrar" onClick={handleSubmit} /> */}
        <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi /> : <></>}</S.ContainerLogoProvi>
      </S.Content>
    </S.Container>
  )
}

export default LoginSanar
