import React, { useContext } from 'react'

import { LoadingProvi, TextButton } from '@provi/provi-components'
import OtpInput from 'react-otp-input'
import * as S from './styles'
import { SanarLogo } from '../../../assets/svg/SanarLogo'
import { ProviLogo } from '../../../assets/svg/ProviLogo'
import { TemplateSanarContext } from '../../../components/template-sanar/context'
import { MadePossibleProvi } from '../../../assets/svg/MadePossibleProvi'
import { ModalSanar } from '../../../components/ModalSanar'
import Button from '../../../components/Button'
import { useOtpAuth } from './hooks'
import { history } from '../../../navigation/history'

const OtpSanar = () => {
  const { isMobile, email, setIsShowLoading, isShowLoading, revalidateOtp } = useContext(TemplateSanarContext)
  const [modalInfo, setModalInfo, otpValue, setOtpValue, partnerSlug, modalInfocontact, setModalInfoContact] = useOtpAuth(
    email,
    setIsShowLoading
  )

  return (
    <S.Container>
      {isShowLoading && <LoadingProvi />}
      <S.Header>
        <SanarLogo />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.Title>
          Enviamos um código de 6 números <br />
          para o e-mail <strong>{email}.</strong>
        </S.Title>
        <S.SubTitle>Insira o código abaixo:</S.SubTitle>
        <OtpInput
          numInputs={6}
          containerStyle={isMobile ? S.containerStyleMobile : S.containerStyle}
          inputStyle={isMobile ? S.inputStyleMobile : S.inputStyle}
          onChange={setOtpValue}
          value={otpValue}
          isInputNum={true}
          shouldAutoFocus={true}
          separator={' '}
        />
        <TextButton
          color="#F56B00"
          text={revalidateOtp ? 'Algo está errado' : 'Não recebi o e-mail'}
          hoverColor="#D35C00"
          pressedColor="#D35C00"
          focusedColor="#D35C00"
          onClick={() => {
            revalidateOtp ? setModalInfoContact(true) : setModalInfo(true)
          }}
        />
        <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi /> : <></>}</S.ContainerLogoProvi>
      </S.Content>
      {modalInfocontact && (
        <ModalSanar
          title="Está tendo problemas?"
          width="430px"
          onDismiss={() => {
            setModalInfoContact(false)
          }}
        >
          <S.ModalText>
            Se você não está recebendo o email com o código de 6 dígitos, verifique se o seu email está escrito corretamente.
          </S.ModalText>
          <S.ModalText>
            Se o seu email estiver correto e mesmo assim você não recebeu o código, entre em contato com o time Provi que vamos
            te ajudar.
          </S.ModalText>
          <Button
            text="Entrar em contato"
            width="100%"
            mobileWidth="100%"
            onClick={() =>
              window.open('https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?')
            }
          />
        </ModalSanar>
      )}
      {modalInfo && (
        <ModalSanar
          title="Não recebeu o seu e-mail?"
          width="430px"
          onDismiss={() => {
            setModalInfo(false)
          }}
        >
          <S.ModalText>
            Caso ainda não tenha recebido o seu email, não se preocupe! O email pode demorar até 30 minutos para chegar, ou
            também pode ter caído na caixa de Spam.
          </S.ModalText>
          <S.ModalText>Se não for o caso, clique no botão abaixo:</S.ModalText>
          <Button
            text="Não recebi o e-mail"
            width="100%"
            mobileWidth="100%"
            onClick={() => history.push(`/login/${partnerSlug}/have-account`)}
          />
        </ModalSanar>
      )}
    </S.Container>
  )
}

export default OtpSanar
