import React, { useContext } from 'react'

import * as S from './styles'
import { SanarLogo } from '../../../assets/svg/SanarLogo'
import { ProviLogo } from '../../../assets/svg/ProviLogo'
import { InputFieldV3, LoadingProvi } from '@provi/provi-components'
import Button from '../../../components/Button'
import { MadePossibleProvi } from '../../../assets/svg/MadePossibleProvi'
import { TemplateSanarContext } from '../../../components/template-sanar/context'
import { useResendEmail } from './hooks'

const HaveAccountSanar = () => {
  const { isMobile, email, setEmail, setIsShowLoading, isShowLoading, setRevalidateOtp } = useContext(TemplateSanarContext)
  const [handleSubmit] = useResendEmail({ email, setIsShowLoading, setRevalidateOtp })
  return (
    <S.Container>
      {isShowLoading && <LoadingProvi />}
      <S.Header>
        <SanarLogo />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.SubTitle>Reescreva o seu e-mail corretamente.</S.SubTitle>
        <InputFieldV3
          placeholder="cecilia@email.com.br"
          label="Digite o seu e-mail"
          isRequired={true}
          width="370px"
          mobileWidth="370px"
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        />
        <Button footerButton text="Avançar" onClick={handleSubmit} />
        <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi /> : <></>}</S.ContainerLogoProvi>
      </S.Content>
    </S.Container>
  )
}

export default HaveAccountSanar
