import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import qs from 'qs'
import Cookies from 'universal-cookie'

import { TemplateContext } from '../../components/template/context'
import { FormFormik } from './form'
import { TokenEmailsModal } from './token-emails-modal'

export const Introduction = (props) => {
  let { partner, campanha } = useParams()
  let [emailLocal] = useState(null)
  let [cookieTokens, setCookieTokens] = useState([])
  let [showModal, setShowModal] = useState(false)
  let [validateTerms, setValidateTerms] = useState(false)
  const { setTypeSign, setEmail, setIsShowLoading, setTypeModal, setScreen, isISA, setPartnerFullName } = useContext(
    TemplateContext
  )

  useEffect(() => {
    const cookies = new Cookies()
    const storedCookieTokens = cookies.get('DASH_tokens')
    if (!partner && storedCookieTokens) {
      setCookieTokens(storedCookieTokens.user)
      setShowModal(!!storedCookieTokens.user.length)
    }
  }, [partner])

  const { search: searchProps } = props.location

  const queryStringParameters = qs.parse(searchProps, { ignoreQueryPrefix: true })

  let propsWrapper = {
    emailLocal,
    setTypeSign,
    setEmail,
    setIsShowLoading,
    setTypeModal,
    setScreen,
    partner,
    campanha,
    validateTerms,
    setValidateTerms,
    isISA,
    setPartnerFullName,
    utmSourcesCode: queryStringParameters,
    ...props,
  }
  return (
    <>
      {showModal && (
        <TokenEmailsModal
          partner={partner}
          isISA={isISA}
          setShowModal={setShowModal}
          setIsShowLoading={setIsShowLoading}
          cookieTokens={cookieTokens}
        />
      )}
      <FormFormik {...propsWrapper} />
    </>
  )
}
