import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import { Person } from '@material-ui/icons'
import { TextErrorInput, Button, showToast } from '@provi/provi-components'
import { signUp, signIn } from '../../services'
import { Form, SubTitle } from '../style'

const FormFormik = (props) => {
  const { values, errors, handleChange, handleSubmit, isSubmitting } = props

  return (
    <Form onSubmit={handleSubmit}>
      <FormControl>
        <SubTitle bold>Reescreva o seu melhor email corretamente:</SubTitle>
        <Input
          placeholder="Digite aqui o seu melhor email"
          inputProps={{ style: { color: 'white' } }}
          value={values.email}
          type="cel"
          onChange={handleChange('email')}
          startAdornment={
            <InputAdornment position="start">
              <Person style={{ color: 'white' }} />
            </InputAdornment>
          }
        />
        {errors.email && <TextErrorInput>{errors.email}</TextErrorInput>}
      </FormControl>
      <Button text="Avançar" backgroundDisabled disabled={!_.isEmpty(errors) || isSubmitting} type="submit" />
    </Form>
  )
}

export const FormHaveAccount = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  validationSchema: () => {
    const schema = {
      email: Yup.string().email('Digite um email válido').required('Preencha o campo Email'),
    }
    return Yup.object().shape(schema)
  },
  handleSubmit: async ({ email }, { props }) => {
    props.setEmail(email)
    props.setTypeModal('wrong')
    props.setIsShowLoading(true)
    let partner = props.partner
    let campaign = props.campanha
    let isISA = props.isISA

    try {
      let response
      if (props.typeSign === 'signIn') {
        response = await signIn({ email, partner, campaign, isISA })
      } else {
        response = await signUp({ email, partner, campaign, isISA })
      }
      if (response.status === 204) {
        props.setScreen('resendingEmail')
      }
      props.setScreen('sendEmail')
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.error.message.split(' ')[0]
        if (['Remova', 'Não'].includes(errorMessage)) {
          showToast(err.response.data.error.message || 'Ocorreu um erro inesperado')
          return false
        }
      }

      console.log(err)
      showToast()
    }
    props.setIsShowLoading(false)
  },
})(FormFormik)
