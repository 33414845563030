import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { theme } from '@provi/provi-components'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiCheckbox from '@material-ui/core/Checkbox'

const FormGroupWrapper = styled(FormGroup)`
  margin-top: 12px;
`

const FormControlWrapper = styled(FormControlLabel)`
  color: white;

  span {
    font-size: 12px;
  }

  a {
    color: white;
    font-weight: bold;
  }
`

const CheckboxWrapper = styled(MuiCheckbox)`
  color: ${theme.colors.pink900} !important;
`

const Checkbox = ({ text, checked, onChange }) => {
  return (
    <FormGroupWrapper>
      <FormControlWrapper control={<CheckboxWrapper checked={checked} onChange={() => onChange()} />} label={text} />
    </FormGroupWrapper>
  )
}

export default Checkbox

Checkbox.propTypes = {
  text: PropTypes.element.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
