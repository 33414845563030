import React from 'react'

export const Text503 = () => {
  return (
    // <svg width="648" height="301" viewBox="0 0 648 301" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg viewBox="0 0 648 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.4757 7H192.105V57.4869H98.3218L86.6031 109.528C89.8545 108.616 93.0382 107.94 96.188 107.467C99.3039 107.028 102.318 106.791 105.163 106.791C131.209 106.791 152.885 115.679 170.124 133.42C187.397 151.161 196 173.87 196 201.581C196 229.528 186.449 253.081 167.381 272.242C148.279 291.403 125.078 301 97.7121 301C73.2248 301 52.1921 294.039 34.5802 280.15C17.0022 266.227 5.45291 247.201 0 223.006H57.4418C61.8787 231.961 67.7041 238.753 74.9183 243.383C82.1324 248.012 90.1255 250.31 98.8637 250.31C110.989 250.31 121.285 245.951 129.752 237.232C138.219 228.514 142.453 217.362 142.453 203.71C142.453 190.463 138.524 179.581 130.633 171.066C122.741 162.55 113.224 158.292 102.013 158.292C96.0186 158.292 90.1254 159.779 84.3338 162.786C78.5422 165.794 72.8184 170.254 67.23 176.236L22.6922 166.301L57.4757 7Z"
        fill="#F7F7F7"
      />
      <path
        d="M325.623 0C346.499 0 364.508 5.1326 379.617 15.3978C394.725 25.663 406.394 41.3309 414.657 62.4354C422.886 83.5398 427 112.917 427 150.601C427 188.657 422.818 218.271 414.454 239.443C406.091 260.615 394.826 276.181 380.696 286.109C366.565 296.036 348.725 301 327.174 301C305.624 301 287.548 296.002 272.878 286.007C258.207 276.012 246.741 260.817 238.444 240.422C230.148 220.026 226 190.987 226 153.336C226 100.727 234.094 62.6042 250.316 38.9672C268.358 13.0003 293.45 0 325.623 0ZM326.871 51.8325C317.664 51.8325 309.536 54.804 302.387 60.6795C295.271 66.5887 289.841 76.7526 286.064 91.1712C282.32 105.59 280.432 126.694 280.432 154.485C280.432 190.717 284.749 215.772 293.348 229.583C301.948 243.427 313.111 250.316 326.837 250.316C340.024 250.316 350.512 243.765 358.303 230.629C367.746 214.793 372.467 188.285 372.467 151.142C372.467 113.491 368.218 87.4906 359.753 73.2071C351.321 58.9574 340.361 51.8325 326.871 51.8325Z"
        fill="#F7F7F7"
      />
      <path
        d="M513.938 77.9345H461.462C464.201 56.7625 471.808 39.7439 484.285 26.8786C501.732 8.94828 523.641 0 550.048 0C573.581 0 593.327 7.46253 609.252 22.3876C625.177 37.3127 633.157 55.0404 633.157 75.5708C633.157 88.301 629.674 99.9169 622.709 110.452C615.744 120.988 605.634 129.497 592.38 135.98C609.793 141.18 623.419 150.23 633.258 163.163C643.063 176.095 648 191.324 648 208.85C648 234.58 638.499 256.36 619.531 274.223C600.529 292.085 576.354 301 546.971 301C519.144 301 496.457 292.592 478.875 275.776C461.327 258.96 451.691 236.066 450 207.094H504.031C506.364 221.884 511.537 232.926 519.55 240.118C527.563 247.31 537.673 250.923 549.879 250.923C562.626 250.923 573.175 246.838 581.594 238.666C589.979 230.494 594.172 220.499 594.172 208.647C594.172 195.647 588.526 184.672 577.199 175.724C565.872 166.776 549.575 162.15 528.24 161.88V115.146C541.359 114.099 551.164 112.073 557.588 109C564.012 105.961 569.016 101.774 572.6 96.4389C576.184 91.1037 577.976 85.4646 577.976 79.4878C577.976 71.6876 575.238 65.2381 569.794 60.1054C564.317 54.9729 557.182 52.4066 548.324 52.4066C540.513 52.4066 533.447 54.7702 527.056 59.5314C520.632 64.2588 516.271 70.4044 513.938 77.9345Z"
        fill="#F7F7F7"
      />
    </svg>
  )
}
