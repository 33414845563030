import React, { useContext, useState } from 'react'

import * as S from './styles'
import { SanarLogo } from '../../../assets/svg/SanarLogo'
import { ProviLogo } from '../../../assets/svg/ProviLogo'
import { TextButton } from '@provi/provi-components'
import { MadePossibleProvi } from '../../../assets/svg/MadePossibleProvi'
import { ModalSanar } from '../../../components/ModalSanar'
import Button from '../../../components/Button'
import { TemplateSanarContext } from '../../../components/template-sanar/context'
import { history } from '../../../navigation/history'
import { useRouteValidation } from '../useRouteValidation'

const SendEmailSanar = () => {
  const [modalInfo, setModalInfo] = useState(false)
  const [partnerSlug, setPartnerSlug] = useState('')

  useRouteValidation(setPartnerSlug)

  const { isMobile, email } = useContext(TemplateSanarContext)
  return (
    <S.Container>
      <S.Header>
        <SanarLogo />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.Title>
          Enviamos um link para o e-mail
          <br />
          <strong>{email}.</strong>
        </S.Title>
        <S.SubTitle>
          Esse link é o acesso para solicitar <br /> {partnerSlug === 'sanar' ? 'a sua mesada.' : 'o seu financiamento.'}
        </S.SubTitle>
        <TextButton
          color="#F56B00"
          text="Não recebi o e-mail"
          hoverColor="#D35C00"
          pressedColor="#D35C00"
          focusedColor="#D35C00"
          onClick={() => {
            setModalInfo(true)
          }}
        />
        <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi /> : <></>}</S.ContainerLogoProvi>
      </S.Content>
      {modalInfo && (
        <ModalSanar
          title="Não recebeu o seu e-mail?"
          width="430px"
          onDismiss={() => {
            setModalInfo(false)
          }}
        >
          <S.ModalText>
            Caso ainda não tenha recebido o seu email, não se preocupe! O email pode demorar até 30 minutos para chegar, ou
            também pode ter caído na caixa de Spam.
          </S.ModalText>
          <S.ModalText>Se não for o caso, clique no botão abaixo:</S.ModalText>
          <Button
            text="Não recebi o e-mail"
            width="100%"
            mobileWidth="100%"
            onClick={() => history.push(`/login/${partnerSlug}/have-account`)}
          />
        </ModalSanar>
      )}
    </S.Container>
  )
}

export default SendEmailSanar
