import { signUp, signIn } from '../../services'

export const useVerificationEmail = (setIsShowLoading, setTypeModal, setScreen, typeSign, partner, campanha, isISA) => {
  const sendEmail = async (email, resend = false) => {
    setIsShowLoading(true)
    let campaign = campanha

    try {
      let response
      if (typeSign === 'signIn') {
        response = await signIn({ email, partner, campaign, resend, isISA })
      } else {
        response = await signUp({ email, partner, campaign, resend, isISA })
      }

      setTypeModal('wrong')
      if (response.status === 204) {
        setScreen('resendingEmail')
      }
      if (response.status === 200) {
        setScreen('sendEmail')
      }
    } catch (err) {
      console.log(err)
    }
    setIsShowLoading(false)
  }

  return [sendEmail]
}
