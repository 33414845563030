import React, { useContext } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { TextErrorInput, Button, InputMasks } from '@provi/provi-components'
import qs from 'qs'

import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { ContainerBack } from '../../../components/container-back'
import { SubTitle } from '../../../components/subtitle'
import { Form } from '../../../components/form'
import { Footer } from '../../../components/footer'
import { ArrowRight } from '../../../assets/svg/arrow-right'
import { useEmailNotFound } from './hooks'

function EmailNotFound({ history, location: { search: searchProps } }) {
  const { setIsShowLoading, setEmailId, setEmail } = useContext(TemplateContext)
  const [cpf, onChange, onSubmit, error] = useEmailNotFound(setIsShowLoading, setEmailId, setEmail, history, searchProps)

  const previousUrl = () => {
    const queryStringParameters = qs.parse(searchProps, { ignoreQueryPrefix: true })

    if (queryStringParameters.isISA === 'true') {
      return `/isa/${queryStringParameters.parceiro}`
    } else if (queryStringParameters.campanha) {
      return `/${queryStringParameters.parceiro}/campanha/${queryStringParameters.campanha}`
    } else if (queryStringParameters.parceiro) {
      return `/${queryStringParameters.parceiro}`
    } else {
      return '/'
    }
  }

  return (
    <Template>
      <ContainerBack onClick={() => history.push(previousUrl())}>
        <ArrowRight color="white" />
      </ContainerBack>
      <div>
        <SubTitle>Não encontramos nenhum acesso vinculado a esse email.</SubTitle>
        <SubTitle bold>Insira o seu CPF para buscarmos o seu cadastro.</SubTitle>
      </div>
      <Form onSubmit={onSubmit}>
        <FormControl>
          <InputMasks
            autoFocus
            mask="999.999.999-99"
            placeholder="Digite aqui o seu CPF"
            inputProps={{ maxlength: 14, style: { color: 'white' } }}
            value={cpf}
            onChange={onChange}
            error={!!error}
          />
          {error && <TextErrorInput text={error} />}
        </FormControl>
      </Form>
      <Footer>
        <Button text="Avançar" onClick={onSubmit} disabled={!!error || !cpf} backgroundDisabled={!!error || !cpf} />
      </Footer>
    </Template>
  )
}

export default EmailNotFound
