import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { initGA } from './components/google-analytics'
import './utils/smartlook'
const { REACT_APP_API_ENV } = process.env

Sentry.init({ dsn: 'https://4727c1202efa449ea888e6caf0076085@sentry.io/2717638', environment: REACT_APP_API_ENV })

initGA()

moment.updateLocale('pt-br', require('moment/locale/pt-br'))
