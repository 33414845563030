import React, { useContext } from 'react'
import { Modal, Button, LoadingProvi, theme } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { ContainerBackground, ContainerLogin, Wrapper, Container, TextModal } from './style'
import { DarkSideIllustration } from '../../components/dark-side-illustration'
import { URL_EXTERNAL } from '../../constants'

export const Template = (props) => {
  const { isShowLoading, isShowModal, isMobile, typeModal, setIsShowModal, modals } = useContext(TemplateContext)

  const isWhiteMode = props.color === theme.colors.white100
  const illustrationToShow = isWhiteMode ? <DarkSideIllustration /> : <ContainerBackground color={props.color} />

  return (
    <Wrapper color={props.color}>
      {isShowLoading && <LoadingProvi isLoadingDark={props.screenIsClear ? false : true} />}
      <ContainerLogin color={props.color} isMobile={isMobile} hasHeader={props.hasHeader}>
        <Container>{props.children}</Container>
      </ContainerLogin>
      {!isMobile && illustrationToShow}
      {isShowModal && (
        <Modal
          title={modals[typeModal].title}
          onDismiss={() => setIsShowModal(false)}
          footer={
            <Button
              type="button"
              text={modals[typeModal].textButton}
              onClick={modals[typeModal].funcButton}
              textLink={{
                text: modals[typeModal].textOpen,
                color: theme.colors.blue900,
                onClick: () => {
                  window.open(URL_EXTERNAL, '__blank')
                },
              }}
            />
          }
        >
          {modals[typeModal].texts.map((item, index) => (
            <TextModal key={index}>{item}</TextModal>
          ))}
        </Modal>
      )}
    </Wrapper>
  )
}
