import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { ROOT_URL, TOKEN_FRONT_END } from '../constants'
import { logger } from '../utils/logger'

export const api = axios.create({
  baseURL: ROOT_URL,
})

const transactionId = Math.random().toString(36).substr(2, 9)

api.interceptors.request.use(
  (config) => {
    Sentry.configureScope(function (scope) {
      scope.setTag('x-request-id', transactionId)
    })
    config.headers['x-request-id'] = transactionId
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export const verifyEmail = async ({
  emailIdentifier,
  code,
  partnerSlug = null,
  isISA = null,
  campaignSlug = null,
  showToast,
}) => {
  const endpoint = '/otp/validate/sign-up'
  const body = {
    emailIdentifier,
    code,
    partnerSlug,
    isISA,
    campaignSlug,
  }

  try {
    const { data } = await api.post(endpoint, body)

    return { error: false, response: data }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.error.message) ||
      'Tivemos problemas ao validar o seu código. Tente novamente mais tarde.'

    showToast(errorMessage)
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + error,
      level: Sentry.Severity.Warning,
    })

    return { error: true }
  }
}

export const resendVerificationCode = async ({ email, partnerSlug = null, showToast }) => {
  const endpoint = '/otp/resend-sign-up'
  const body = {
    emailIdentifier: email,
    partnerSlug,
  }

  try {
    await api.post(endpoint, body)
    return { error: false }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.error.message) ||
      'Tivemos problemas ao reenviar o seu código. Tente novamente mais tarde.'

    showToast(errorMessage)
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + error,
      level: Sentry.Severity.Warning,
    })

    return { error: true }
  }
}

export const signInOTP = async ({ email, partnerSlug = null, campaignSlug = null, isISA = false }) => {
  const endpoint = '/otp/sign-in'
  const body = { emailIdentifier: email, partnerSlug, campaignSlug, isISA }

  try {
    await api.post(endpoint, body)
    return { error: false }
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + error,
      level: Sentry.Severity.Warning,
    })

    return { error: true }
  }
}

export const signInUsingCPF = async (cpf) => {
  const endpoint = `/otp/sign-in/cpf/${cpf}`

  try {
    const { data } = await api.post(endpoint)
    return { error: false, response: data }
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + error,
      level: Sentry.Severity.Warning,
    })

    return { error: true }
  }
}

export const signUp = ({ email, partner, campaign, resend = false, isISA = false, OTP = false, utmSourcesCode = null }) => {
  let endpoint = resend ? '/resend-sign-up' : '/sign-up'
  OTP && (endpoint = `/otp${endpoint}`)

  let data
  if (partner) {
    data = {
      partnerSlug: partner.toLowerCase(),
      email,
      utmSourcesCode,
    }
    if (campaign) {
      data.campaignSlug = campaign
    }
  } else {
    data = {
      email,
    }
  }

  if (isISA) {
    data.isISA = true
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(endpoint, data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const signIn = (body) => {
  const endpoint = body.resend ? '/resend-sign-in' : '/sign-in'

  let data
  if (body.partner) {
    data = {
      partnerSlug: body.partner && body.partner.toLowerCase(),
      email: body.email,
    }
    if (body.campaign) {
      data.campaignSlug = body.campaign
    }
  } else {
    data = {
      email: body.email,
    }
  }

  if (body.isISA) {
    data.isISA = true
  }

  if (body.previouslyLogged) {
    data.previouslyLogged = true
    data.previouslyLoggedUserToken = body.previouslyLoggedUserToken
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(endpoint, data)
      resolve(response)
    } catch (err) {
      logger(err)
      reject(err)
    }
  })
}

export const signInCpf = (body) => {
  const data = {
    partnerSlug: body.partner && body.partner.toLowerCase(),
  }
  if (body.campaign) {
    data.campaignSlug = body.campaign
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/sign-in/cpf/${body.cpf}`, data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

/**
 * @param {string} slug - slug of the partner
 * @param {boolean} isIsa - if url contains /isa/
 */
export const getSlugInfo = async (slug, isIsa) => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.Authorization = TOKEN_FRONT_END
      const response = await api.get(`/partner/${slug}/name`, {
        params: {
          isIsa,
        },
      })

      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const createAccountSanar = async (email, partnerSlug, utmSourcesCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/sign-up', {
        email,
        partnerSlug,
        utmSourcesCode,
        useNewSdk: true,
      })
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: `ERROR INFO ${err}`,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const createAccountOtpSanar = async (email, partnerSlug, utmSourcesCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/otp/sign-up', {
        email,
        partnerSlug,
        utmSourcesCode,
        useNewSdk: true,
      })
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: `ERROR INFO ${err}`,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const validateOTP = async ({ emailIdentifier, code, partnerSlug, useNewSdk }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/otp/validate/sign-up', {
        emailIdentifier,
        code,
        partnerSlug,
        useNewSdk,
      })
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: `ERROR INFO ${err}`,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}
