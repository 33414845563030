import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { validateOTP } from '../../../services'
import { useRouteValidation } from '../useRouteValidation'

export const useOtpAuth = (email, setIsShowLoading) => {
  const [modalInfo, setModalInfo] = useState(false)
  const [modalInfoContact, setModalInfoContact] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [partnerSlug, setPartnerSlug] = useState('')

  useRouteValidation(setPartnerSlug)

  useEffect(() => {
    const validadeCode = async () => {
      setIsShowLoading(true)
      try {
        const response = await validateOTP({ emailIdentifier: email, code: otpValue, partnerSlug, useNewSdk: true })
        window.location.href = response.data.redirectUrl
      } catch (err) {
        toast.error(err?.response?.data?.error?.message || 'Ocorreu um erro inesperado')
      }
      setIsShowLoading(false)
    }
    if (otpValue.length === 6) {
      validadeCode()
    }
  }, [otpValue, setIsShowLoading, email, partnerSlug])

  return [modalInfo, setModalInfo, otpValue, setOtpValue, partnerSlug, modalInfoContact, setModalInfoContact]
}
