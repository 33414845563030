import styled from 'styled-components'

import { theme } from '@provi/provi-components'

export const Container = styled.div`
  font-family: 'Montserrat', sans serif;
  width: 100%;
  min-height: 100vh;

  background: #ffffff;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 60px;
  background: #575757;

  padding: 0 5%;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const MobileHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 60px;

  padding: 5%;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  padding: 5%;

  ${theme.breakpoints.down('md')} {
    width: 90%;
  }

  ${theme.breakpoints.up('sm')} {
    button {
      display: none;
    }
  }
`

export const Title = styled.h1`
  color: #f56b00;
  font-weight: 600;
  font-size: 32px;

  ${theme.breakpoints.down('md')} {
    font-size: 26px;
  }
`

export const Subtitle = styled.p`
  font-size: 22px;
  line-height: 32px;

  ${theme.breakpoints.down('md')} {
    font-size: 18px;
    line-height: 28px;
  }
`
