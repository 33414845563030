import * as Sentry from '@sentry/browser'
import { getSlugPartner } from '../utils'

export const logger = (props) => {
  const partner = getSlugPartner()

  console.log('ERROR ->>>>>: ', props && props.error)
  console.log('INFO EXTRA ->>>>>: ', props && props.info && props.info.componentStack)

  if (props && props.error) {
    Sentry.withScope((scope) => {
      partner && scope.setTag('PARTNER', partner)
      Object.keys(props.info).forEach((key) => {
        scope.setExtra(key, props.info[key])
      })
      Sentry.captureException(props.error)
    })
  }
}
