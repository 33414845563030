import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import qs from 'qs'
import smartlook from '../../../utils/smartlook'

import { resendVerificationCode as resendVerificationCodeService, verifyEmail as verifyEmailService } from '../../../services'

export const useVerificationCode = (setIsShowLoading, showToast, searchProps, EmailId, email, setEmail) => {
  const [verificationCode, setVerificationCode] = useState('')
  const [partnerSlug, setPartnerSlug] = useState('')
  const [isISA, setIsISA] = useState(false)
  const [campaignSlug, setCampaignSlug] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (process.env.REACT_APP_API_ENV === 'production') {
      smartlook.resume()

      return () => {
        smartlook.pause()
      }
    }
  }, [])

  useEffect(() => {
    const queryStringParameters = qs.parse(searchProps, { ignoreQueryPrefix: true })

    !email && !EmailId && setEmail(queryStringParameters.email)
    setPartnerSlug(queryStringParameters.parceiro)
    setIsISA(queryStringParameters.isa === 'true')
    setCampaignSlug(queryStringParameters.campanha)

    if (process.env.REACT_APP_API_ENV === 'production') {
      smartlook.identify(EmailId || email || 'email@provi.com.br', {
        partnerSlug: queryStringParameters.parceiro,
        isISA: queryStringParameters.isa,
        campaignSlug: queryStringParameters.campanha,
        email: EmailId || email
      })
    }

    queryStringParameters.codigo && setVerificationCode(queryStringParameters.codigo.substr(0, 6))
  }, [EmailId, email, searchProps, setEmail])

  const onChange = ({ target: { value } }) => {
    if (isNaN(value)) {
      setError('Seu código de verificação precisa ser um número')
      return
    }

    setVerificationCode(value)

    const schema = Yup.object().shape({
      verificationCode: Yup.string()
        .required('Código de verificação é obrigatório')
        .max(6, 'Seu código de verificação tem que ter 6 dígitos')
        .min(6, 'Seu código de verificação tem que ter 6 dígitos')
    })

    if (!schema.isValidSync({ verificationCode: value })) {
      try {
        schema.validateSync({ verificationCode: value })
      } catch (err) {
        setError(err.errors[0])
      }
    } else {
      setError('')
    }
  }

  const onSubmit = async event => {
    event.preventDefault()
    setIsShowLoading(true)

    if (process.env.REACT_APP_API_ENV === 'production') {
      smartlook.track('code', {
        code: verificationCode
      })
    }

    const { error: emailError, response } = await verifyEmailService({
      emailIdentifier: EmailId || email,
      partnerSlug,
      isISA,
      campaignSlug,
      code: verificationCode,
      showToast
    })

    if (!emailError) {
      window.location.href = response.redirectUrl
    } else {
      if (process.env.REACT_APP_API_ENV === 'production') {
        smartlook.error(error)
      }
    }

    setIsShowLoading(false)
  }

  const resendVerificationCode = async () => {
    setIsShowLoading(true)
    const { error: verificationCodeError } = await resendVerificationCodeService({
      email: EmailId || email,
      partnerSlug,
      showToast
    })
    setIsShowLoading(false)
    !verificationCodeError && showToast('Código reenviado!')
  }

  return [verificationCode, onChange, onSubmit, resendVerificationCode, error]
}
