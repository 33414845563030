import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { TemplateContext } from '../../components/template/context'
import { FormFormik } from './form'

export const SearchSignup = (props) => {
  let { partner, campanha } = useParams()
  const { setIsShowLoading, setTypeModal, setScreen, setEmail } = useContext(TemplateContext)
  let propsWrapper = { setIsShowLoading, setTypeModal, setScreen, partner, campanha, setEmail, ...props }
  return <FormFormik {...propsWrapper} />
}
