import React, { useContext } from 'react'
import { TemplateContext } from '../components/template/context'
import { Template } from '../components/template'
import { Introduction } from '../screens/introduction'
import { SearchSignup } from '../screens/search-signup'
import { SendEmail } from '../screens/send-email'
import { SignIn } from '../screens/sign-in'
import { EmailNotFound } from '../screens/email-not-found'
import { HaveAccount } from '../screens/have-account'
import { ResendingEmail } from '../screens/resending-email'
import { VerificationEmail } from '../screens/verification-email'
import { CridNotFound } from '../screens/crid-not-found'
import { usePartner } from './hooks'

import 'react-toastify/dist/ReactToastify.css'

export const Authentication = (props) => {
  const { screen, setPartner } = useContext(TemplateContext)
  usePartner(setPartner)
  const screens = {
    introduction: <Introduction {...props} />,
    sendEmail: <SendEmail {...props} />,
    resendingEmail: <ResendingEmail {...props} />,
    veficationEmail: <VerificationEmail {...props} />,
    haveAccount: <HaveAccount {...props} />,
    signIn: <SignIn {...props} />,
    searchSignup: <SearchSignup {...props} />,
    emailNotFound: <EmailNotFound {...props} />,
    cridNotFound: <CridNotFound {...props} />,
  }

  return <Template>{screens[screen]}</Template>
}
