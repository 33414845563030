import { useState } from 'react'
import * as Yup from 'yup'

export const useSignIn = (setIsShowLoading, setEmail) => {
  const [error, setError] = useState('')

  const onChange = ({ target: { value } }) => {
    setEmail(value)

    const schema = Yup.object().shape({
      email: Yup.string()
        .email('Digite um endereço de e-mail válido')
        .max(64, 'Seu endereço de e-mail pode ter no máximo 64 caracteres')
        .required('Endereço de e-mail é obrigatório'),
    })

    if (!schema.isValidSync({ email: value })) {
      try {
        schema.validateSync({ email: value })
      } catch (err) {
        setError(err.errors[0])
      }
    } else {
      setError('')
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setIsShowLoading(true)
    setIsShowLoading(false)
  }

  return [onChange, onSubmit, error]
}
