import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${theme.colors.blue900};
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${theme.colors.white100};
`

export const Text = styled.span`
  font-family: ${({ bold }) => `${bold ? 'CenturyGothicBold' : 'CenturyGothic'}, sans-serif`};
  color: ${({ color = theme.colors.white100 }) => color};
`
