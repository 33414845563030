import React, { useContext, useState } from 'react'

import * as S from './styles'
import { SanarLogo } from '../../../assets/svg/SanarLogo'
import { ProviLogo } from '../../../assets/svg/ProviLogo'
import { TextButton } from '@provi/provi-components'
import { MadePossibleProvi } from '../../../assets/svg/MadePossibleProvi'
import { ModalSanar } from '../../../components/ModalSanar'
import Button from '../../../components/Button'
import { TemplateSanarContext } from '../../../components/template-sanar/context'
import { useRouteValidation } from '../useRouteValidation'

const EmailNotFoundSanar = () => {
  useRouteValidation()

  const [modalInfo, setModalInfo] = useState(false)

  const { isMobile, email } = useContext(TemplateSanarContext)
  return (
    <S.Container>
      <S.Header>
        <SanarLogo />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.Title>
          Enviamos um link para o e-mail
          <br />
          <strong>{email}.</strong>
        </S.Title>
        <S.SubTitle>
          Esse link é o acesso para solicitar a sua <br /> mesada.
        </S.SubTitle>
        <TextButton
          color="#F56B00"
          text="Algo está errado"
          hoverColor="#D35C00"
          pressedColor="#D35C00"
          focusedColor="#D35C00"
          onClick={() => {
            setModalInfo(true)
          }}
        />
        <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi /> : <></>}</S.ContainerLogoProvi>
      </S.Content>
      {modalInfo && (
        <ModalSanar
          title="Ainda não recebeu o seu e-mail?"
          width="430px"
          onDismiss={() => {
            setModalInfo(false)
          }}
        >
          <S.ModalText>
            Se você tem certeza que o seu email está <br />
            correto e mesmo assim não está recebendo o <br />
            seu link de acesso, entre em contato com a <br />
            gente que vamos te ajudar :)
          </S.ModalText>
          <Button
            text="Entrar em contato"
            width="100%"
            mobileWidth="100%"
            onClick={() =>
              window.open('https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?')
            }
          />
        </ModalSanar>
      )}
    </S.Container>
  )
}

export default EmailNotFoundSanar
