import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    align-items: center;
  }
`
