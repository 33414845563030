import React from 'react'

export const CloseButton = ({ onClick }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      <path
        d="M3.70713 2.29292C3.31661 1.9024 2.68344 1.9024 2.29292 2.29292C1.9024 2.68345 1.9024 3.31661 2.29292 3.70714L3.70713 2.29292ZM16.2929 17.7071C16.6835 18.0976 17.3166 18.0976 17.7071 17.7071C18.0977 17.3165 18.0977 16.6834 17.7071 16.2929L16.2929 17.7071ZM17.7071 3.70708C18.0977 3.31655 18.0977 2.68339 17.7071 2.29286C17.3166 1.90234 16.6834 1.90234 16.2929 2.29286L17.7071 3.70708ZM2.29292 16.2929C1.9024 16.6834 1.9024 17.3166 2.29292 17.7071C2.68345 18.0976 3.31661 18.0976 3.70714 17.7071L2.29292 16.2929ZM2.29292 3.70714L9.29291 10.7071L10.7071 9.29288L3.70713 2.29292L2.29292 3.70714ZM9.29291 10.7071L16.2929 17.7071L17.7071 16.2929L10.7071 9.29288L9.29291 10.7071ZM10.7071 10.7071L17.7071 3.70708L16.2929 2.29286L9.29291 9.29288L10.7071 10.7071ZM9.29291 9.29288L2.29292 16.2929L3.70714 17.7071L10.7071 10.7071L9.29291 9.29288Z"
        fill="black"
      />
    </svg>
  )
}
