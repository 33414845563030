import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { TemplateContext } from '../../components/template/context'
import { FormFormik } from './form'

export const EmailNotFound = (props) => {
  let { partner, campanha } = useParams()
  const { setIsShowLoading, setEmail, setScreen, setIsShowModal, typeSign, isISA } = useContext(TemplateContext)
  let propsWrapper = { setIsShowLoading, setEmail, setScreen, setIsShowModal, typeSign, partner, campanha, isISA, ...props }
  return <FormFormik {...propsWrapper} />
}
