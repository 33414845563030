import React from 'react'

export const SanarLogo = () => (
  <svg width="130" height="28" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6008 10.9472C18.935 10.3619 18.1413 10.0171 17.3175 9.8982C16.3691 9.76155 15.9794 8.60747 16.6569 7.92915C18.3794 6.20455 18.5837 3.39678 16.9761 1.56401C15.2143 -0.444379 12.1563 -0.518347 10.299 1.34124L1.42062 10.23C-0.278155 11.9312 -0.512318 14.6919 1.03826 16.5294C1.78137 17.4097 2.76685 17.9331 3.79941 18.1004C4.74126 18.2532 5.12577 19.3934 4.45093 20.0691L4.4492 20.0708C2.72624 21.7954 2.52231 24.6032 4.12993 26.436C5.89177 28.4444 8.9497 28.5183 10.8071 26.6587L19.822 17.6329C21.6806 15.7724 21.6067 12.7112 19.6008 10.9472Z"
      fill="#F56B00"
    />
    <path
      d="M27.3232 19.5898L28.6695 17.4248C30.014 18.2164 31.3585 18.4807 32.7561 18.4807C34.0734 18.4807 34.9703 17.9261 34.9703 17.0553C34.9703 16.1842 33.9688 15.7084 32.5453 15.3394L31.3585 15.0228C28.9594 14.389 27.3232 13.4656 27.3232 11.2214C27.3232 8.39674 29.5387 6.52203 32.9138 6.52203C35.0489 6.52203 36.8682 6.971 38.2659 7.92136L36.7637 10.271C35.63 9.63737 34.416 9.34664 33.1505 9.34664C31.8592 9.34664 30.9623 9.82208 30.9623 10.6405C30.9623 11.4852 31.8592 11.8811 33.0987 12.1986L34.2855 12.5412C36.7105 13.1748 38.6348 14.2839 38.6348 16.7915C38.6348 19.4838 36.2629 21.2798 32.703 21.2798C30.5402 21.2794 28.6159 20.7248 27.3232 19.5898Z"
      fill="#808080"
    />
    <path
      d="M52.5589 12.0648V21.0137H49.1052L49.0261 18.9806C48.076 20.4591 46.4416 21.2779 44.7014 21.2779C41.7484 21.2779 39.771 19.5087 39.771 16.8689C39.771 14.1234 42.196 12.3282 45.835 12.3282C46.8106 12.3282 47.8911 12.4338 48.9733 12.6448V12.3814C48.9733 10.4807 47.7079 9.34487 45.5451 9.34487C44.2007 9.34487 43.1201 9.74149 42.2232 10.4807L40.6679 8.369C42.196 7.12836 43.9898 6.52063 46.4675 6.52063C50.1066 6.52106 52.5589 8.71153 52.5589 12.0648ZM48.973 15.0741C48.0501 14.8895 47.1005 14.7839 46.2822 14.7839C44.5947 14.7839 43.5141 15.5764 43.5141 16.7901C43.5141 17.9515 44.2789 18.7176 45.7297 18.7176C47.3904 18.7172 48.9457 17.397 48.973 15.0741Z"
      fill="#808080"
    />
    <path
      d="M68.1128 12.0648V21.0137H64.5271V12.6453C64.5271 10.7978 63.2098 9.5832 61.2052 9.5832C59.2023 9.5832 57.8833 10.7978 57.8833 12.6453V21.0137H54.2974V6.78536H57.7511L57.8042 8.58051C58.8847 7.23346 60.5463 6.52106 62.4447 6.52106C65.8463 6.52106 68.1128 8.7384 68.1128 12.0648Z"
      fill="#808080"
    />
    <path
      d="M82.5088 12.0648V21.0137H79.0547L78.976 18.9806C78.0257 20.4591 76.3916 21.2779 74.6509 21.2779C71.698 21.2779 69.7207 19.5087 69.7207 16.8689C69.7207 14.1234 72.1458 12.3282 75.7845 12.3282C76.7602 12.3282 77.8405 12.4338 78.9234 12.6448V12.3814C78.9234 10.4807 77.6578 9.34487 75.4946 9.34487C74.1504 9.34487 73.0701 9.74149 72.1731 10.4807L70.6177 8.369C72.1458 7.12836 73.9392 6.52063 76.417 6.52063C80.0563 6.52106 82.5088 8.71153 82.5088 12.0648ZM78.9227 15.0741C77.9997 14.8895 77.0501 14.7839 76.2317 14.7839C74.5443 14.7839 73.464 15.5764 73.464 16.7901C73.464 17.9515 74.2284 18.7176 75.6792 18.7176C77.34 18.7172 78.8954 17.397 78.9227 15.0741Z"
      fill="#808080"
    />
    <path
      d="M92.974 6.52106L92.7374 9.82108C89.7844 9.82108 87.8331 11.2464 87.8331 13.3846V21.0141H84.2471V6.78536H87.7005L87.7538 8.68601C88.8874 7.2867 90.6801 6.52106 92.974 6.52106Z"
      fill="#808080"
    />
    <path
      d="M113.144 6.78537V21.0141H109.69L109.637 19.2713C108.609 20.5915 107.054 21.2784 105.26 21.2784C102.044 21.2784 99.855 19.0602 99.855 15.7078V6.78537H103.441V15.1537C103.441 16.9748 104.681 18.1895 106.526 18.1895C108.345 18.1895 109.558 16.9748 109.558 15.1537V6.78537H113.144Z"
      fill="#808080"
    />
    <path
      d="M130 13.8335C130 18.295 127.02 21.2784 123.197 21.2784C121.035 21.2784 119.48 20.4072 118.478 18.9555V26.8225H114.892V6.78536H118.346L118.424 8.92311C119.426 7.41909 121.009 6.52106 123.197 6.52106C127.02 6.52106 130 9.42485 130 13.8335ZM126.282 13.86C126.282 11.2991 124.7 9.5832 122.38 9.5832C120.034 9.5832 118.478 11.2991 118.478 13.8868C118.478 16.4735 120.034 18.1895 122.38 18.1895C124.699 18.1895 126.282 16.4735 126.282 13.86Z"
      fill="#808080"
    />
  </svg>
)
