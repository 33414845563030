import React, { useContext } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { TextLink } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { SubTitle, Bold } from '../style'

export const SendEmail = () => {
  const { modals, typeModal, setIsShowModal, email, typeSign } = useContext(TemplateContext)
  return (
    <FormControl>
      <SubTitle margin>Enviamos um link para o email</SubTitle>
      <Bold>{email}</Bold>
      <SubTitle>
        {typeSign === 'signUp'
          ? 'Esse link é o seu acesso para a nossa plataforma.'
          : 'Esse link é o seu acesso para retomar a sua solicitação de onde você parou ;)'}
      </SubTitle>
      <TextLink text={modals[typeModal].textOpen} color="white" onClick={() => setIsShowModal(true)} />
    </FormControl>
  )
}
