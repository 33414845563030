import ReactGA from 'react-ga'
import { trackingId, sdkBaseURL } from '../../constants'

/**
 * @description Initialize the GA, set debug to true in order to keep track
 * of what information is being send to analytics, the other setup will create a
 * cross domain reference to the SDK
 * @return {void}
 */
export const initGA = () => {
  ReactGA.initialize(trackingId, { debug: false, gaOptions: { allowLinker: true } })
  ReactGA.ga('require', 'linker')
  ReactGA.ga('linker:autoLink', [`${sdkBaseURL}`])
  ReactGA.ga('send', 'pageview')
}

/**
 * @description Track page views, and set dimensions for the user and for the session
 * @return {void}
 */
export const trackPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search)
}

/**
 * @description Dispatch events for the google analytics, can be used
 * to trigger specific conversion goals
 * @param {Object} data
 * @param {string} data.category
 * @param {string} data.action
 * @param {string} data.label
 * @return {void}
 */
export const trackEvent = ({ category, action, label }) => {
  ReactGA.event({
    category,
    action,
    label,
  })
}
