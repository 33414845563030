import { useState } from 'react'
import * as Yup from 'yup'
import qs from 'qs'

import { signUp } from '../../../services'

export const useCPFNotFound = (setIsShowLoading, email, setEmail, searchProps, history) => {
  const [error, setError] = useState('')

  const onChange = ({ target: { value } }) => {
    setEmail(value)

    const schema = Yup.object().shape({
      email: Yup.string()
        .email('Digite um endereço de e-mail válido')
        .max(64, 'Seu endereço de e-mail pode ter no máximo 64 caracteres')
        .required('Endereço de e-mail é obrigatório'),
    })

    if (!schema.isValidSync({ email: value })) {
      try {
        schema.validateSync({ email: value })
      } catch (err) {
        setError(err.errors[0])
      }
    } else {
      setError('')
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setIsShowLoading(true)

    const queryStringParameters = qs.parse(searchProps, { ignoreQueryPrefix: true })

    const params = {
      campaign: queryStringParameters.campanha,
      email,
      partner: queryStringParameters.parceiro,
      OTP: true,
      isISA: queryStringParameters.isa === 'true',
    }

    const { error: signUpError } = await signUp(params)

    !signUpError &&
      history.push({
        pathname: '/verificacao',
        search: searchProps,
      })

    setIsShowLoading(false)
  }

  return [onChange, onSubmit, error]
}
