import React from 'react'

export const ProviIcon = () => {
  return (
    <svg width="130" height="39" viewBox="0 0 130 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.1671 20.8794C63.1671 26.5701 58.9801 30.9627 53.9011 30.9627C51.3018 30.9627 49.4014 30.0842 48.1233 28.632V38.0663H42.3056V11.3334H48.1233V13.1297C49.4014 11.6776 51.3018 10.799 53.9011 10.799C58.9801 10.799 63.1671 15.1886 63.1671 20.8794ZM57.3494 20.8794C57.3494 18.0144 55.4091 16.2211 52.7363 16.2211C50.0604 16.2211 48.1233 18.0174 48.1233 20.8794C48.1233 23.7443 50.0635 25.5376 52.7363 25.5376C55.4122 25.5376 57.3494 23.7443 57.3494 20.8794Z"
        fill="#000B3C"
      />
      <path
        d="M78.0945 10.9514V17.4422C75.6914 17.0618 72.2799 18.0158 72.2799 21.7955V30.4267H66.4622V11.3348H72.2799V14.7342C73.0523 12.1741 75.6516 10.9514 78.0945 10.9514Z"
        fill="#000B3C"
      />
      <path
        d="M79.2556 20.8794C79.2556 15.1886 83.8288 10.799 89.53 10.799C95.2312 10.799 99.8045 15.1916 99.8045 20.8794C99.8045 26.5701 95.2282 30.9627 89.53 30.9627C83.8319 30.9627 79.2556 26.5701 79.2556 20.8794ZM93.9899 20.8794C93.9899 18.1683 92.0496 16.372 89.53 16.372C87.0105 16.372 85.0702 18.1683 85.0702 20.8794C85.0702 23.5904 87.0105 25.3867 89.53 25.3867C92.0527 25.3867 93.9899 23.5904 93.9899 20.8794Z"
        fill="#000B3C"
      />
      <path
        d="M121.133 11.3345L113.96 30.4294H107.37L100.198 11.3345H106.635L110.668 23.4405L114.702 11.3345H121.133Z"
        fill="#000B3C"
      />
      <path
        d="M122.682 6.2166C122.682 4.34485 124.273 2.78103 126.171 2.78103C128.068 2.78103 129.659 4.34787 129.659 6.2166C129.659 8.08836 128.068 9.6552 126.171 9.6552C124.273 9.6552 122.682 8.08534 122.682 6.2166ZM123.265 11.3337H129.082V30.4287H123.265V11.3337Z"
        fill="#000B3C"
      />
      <path
        d="M33.9222 6.56045C26.4891 -1.84434 13.5266 -2.17341 5.65829 5.57325C1.85442 9.32279 -0.0275865 14.2437 0.00306516 19.1555L0 19.1525V38.0663H19.2032L19.2002 38.0633C24.5581 38.0965 29.9283 35.953 33.8179 31.6238C40.1874 24.5323 40.2364 13.7003 33.9222 6.56045ZM24.506 24.1368C21.6431 26.9565 17.0025 26.9565 14.1396 24.1368C11.2767 21.3171 11.2767 16.7464 14.1396 13.9267C17.0025 11.107 21.6431 11.107 24.506 13.9267C27.3688 16.7464 27.3688 21.3171 24.506 24.1368Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="38.6268" y1="19.0329" x2="0" y2="19.0329" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2369F1" />
          <stop offset="0.999" stopColor="#06C1EF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
