import React from 'react'

export const LogoSanarBranch = () => {
  return (
    <svg width="150" height="33" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6008 10.9472C18.935 10.3619 18.1413 10.0171 17.3175 9.8982C16.3691 9.76155 15.9794 8.60747 16.6569 7.92915C18.3794 6.20455 18.5837 3.39678 16.9761 1.56401C15.2143 -0.444379 12.1563 -0.518347 10.299 1.34124L1.42062 10.23C-0.278155 11.9312 -0.512318 14.6919 1.03826 16.5294C1.78137 17.4097 2.76685 17.9331 3.79941 18.1004C4.74126 18.2532 5.12577 19.3934 4.45093 20.0691L4.4492 20.0708C2.72624 21.7954 2.52231 24.6032 4.12993 26.436C5.89177 28.4444 8.9497 28.5183 10.8071 26.6587L19.822 17.6329C21.6806 15.7724 21.6067 12.7112 19.6008 10.9472Z"
        fill="#F56B00"
      />
      <path
        d="M27.3234 19.5897L28.6696 17.4247C30.0141 18.2163 31.3586 18.4806 32.7562 18.4806C34.0735 18.4806 34.9704 17.926 34.9704 17.0553C34.9704 16.1841 33.9689 15.7083 32.5454 15.3393L31.3586 15.0227C28.9595 14.389 27.3234 13.4655 27.3234 11.2213C27.3234 8.39668 29.5388 6.52197 32.9139 6.52197C35.049 6.52197 36.8683 6.97094 38.266 7.9213L36.7638 10.271C35.6301 9.63731 34.4161 9.34658 33.1507 9.34658C31.8593 9.34658 30.9624 9.82202 30.9624 10.6404C30.9624 11.4852 31.8593 11.881 33.0989 12.1985L34.2856 12.5411C36.7106 13.1748 38.635 14.2839 38.635 16.7914C38.635 19.4837 36.263 21.2797 32.7031 21.2797C30.5403 21.2793 28.616 20.7247 27.3234 19.5897Z"
        fill="white"
      />
      <path
        d="M52.5591 12.0647V21.0136H49.1054L49.0263 18.9805C48.0763 20.459 46.4418 21.2778 44.7016 21.2778C41.7487 21.2778 39.7712 19.5086 39.7712 16.8687C39.7712 14.1232 42.1962 12.3281 45.8353 12.3281C46.8108 12.3281 47.8913 12.4336 48.9736 12.6447V12.3813C48.9736 10.4806 47.7082 9.34474 45.5454 9.34474C44.2009 9.34474 43.1204 9.74137 42.2235 10.4806L40.6681 8.36888C42.1963 7.12824 43.9901 6.52051 46.4678 6.52051C50.1069 6.52094 52.5591 8.71141 52.5591 12.0647ZM48.9732 15.074C48.0503 14.8893 47.1007 14.7838 46.2825 14.7838C44.5949 14.7838 43.5144 15.5762 43.5144 16.79C43.5144 17.9514 44.2791 18.7175 45.7299 18.7175C47.3906 18.7171 48.946 17.3969 48.9732 15.074Z"
        fill="white"
      />
      <path
        d="M68.1131 12.0647V21.0136H64.5273V12.6452C64.5273 10.7977 63.2101 9.58314 61.2055 9.58314C59.2025 9.58314 57.8835 10.7977 57.8835 12.6452V21.0136H54.2976V6.7853H57.7513L57.8044 8.58045C58.885 7.2334 60.5466 6.521 62.4449 6.521C65.8465 6.521 68.1131 8.73834 68.1131 12.0647Z"
        fill="white"
      />
      <path
        d="M82.5085 12.0647V21.0136H79.0544L78.9758 18.9805C78.0255 20.459 76.3914 21.2778 74.6507 21.2778C71.6978 21.2778 69.7205 19.5086 69.7205 16.8687C69.7205 14.1232 72.1456 12.3281 75.7843 12.3281C76.7599 12.3281 77.8402 12.4336 78.9231 12.6447V12.3813C78.9231 10.4806 77.6576 9.34474 75.4944 9.34474C74.1502 9.34474 73.0699 9.74137 72.1729 10.4806L70.6175 8.36888C72.1456 7.12824 73.9389 6.52051 76.4167 6.52051C80.0561 6.52094 82.5085 8.71141 82.5085 12.0647ZM78.9225 15.074C77.9995 14.8893 77.0498 14.7838 76.2315 14.7838C74.5441 14.7838 73.4638 15.5762 73.4638 16.79C73.4638 17.9514 74.2282 18.7175 75.679 18.7175C77.3397 18.7171 78.8952 17.3969 78.9225 15.074Z"
        fill="white"
      />
      <path
        d="M92.9742 6.521L92.7376 9.82102C89.7847 9.82102 87.8334 11.2463 87.8334 13.3845V21.014H84.2473V6.7853H87.7008L87.7541 8.68595C88.8877 7.28664 90.6804 6.521 92.9742 6.521Z"
        fill="white"
      />
      <path
        d="M113.144 6.78516V21.0139H109.69L109.637 19.2711C108.609 20.5913 107.054 21.2782 105.26 21.2782C102.044 21.2782 99.855 19.06 99.855 15.7076V6.78516H103.441V15.1535C103.441 16.9746 104.681 18.1893 106.526 18.1893C108.345 18.1893 109.558 16.9746 109.558 15.1535V6.78516H113.144Z"
        fill="white"
      />
      <path
        d="M130 13.8335C130 18.2949 127.02 21.2783 123.197 21.2783C121.035 21.2783 119.48 20.4072 118.477 18.9555V26.8225H114.891V6.7853H118.346L118.424 8.92305C119.426 7.41903 121.009 6.521 123.197 6.521C127.02 6.521 130 9.42479 130 13.8335ZM126.282 13.8599C126.282 11.2991 124.7 9.58314 122.379 9.58314C120.034 9.58314 118.478 11.2991 118.478 13.8867C118.478 16.4734 120.034 18.1894 122.379 18.1894C124.699 18.1894 126.282 16.4734 126.282 13.8599Z"
        fill="white"
      />
    </svg>
  )
}
