import styled from 'styled-components'

export const ContainerBack = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: -60px;
  left: 0;
  z-index: 1;
  cursor: pointer;
`
