import { useState } from 'react'
import qs from 'qs'
import { toast } from 'react-toastify'

import { history } from '../../../navigation/history'
import { createAccountOtpSanar, createAccountSanar } from '../../../services'
import { hasMicrosoftDomain } from '../../../utils'
import { useRouteValidation } from '../useRouteValidation'
import { alreadyHasContractMessage } from '../../../constants'

export const useAuthSanar = ({ email, setIsShowLoading }) => {
  const [partnerSlug, setPartnerSlug] = useState('')

  useRouteValidation(setPartnerSlug)

  const getQueryStrings = () => {
    try {
      const { search: searchProps } = window?.location

      const utmSourcesCode = qs.parse(searchProps, { ignoreQueryPrefix: true })

      const isEmptySearchProps = Object.values(utmSourcesCode).length === 0

      const localUtmSourcesCode = localStorage.getItem('utmSourcesCode') && JSON.parse(localStorage.getItem('utmSourcesCode'))

      const now = new Date().getTime()

      if (isEmptySearchProps && localUtmSourcesCode) {
        const isExpired = now < localUtmSourcesCode.expiryDate

        return !isExpired ? {} : localUtmSourcesCode
      }

      if (!isEmptySearchProps) {
        const expiryPeriod = 90 * 24 * 60 * 60 * 1000
        const expiryDate = now + expiryPeriod

        localStorage.setItem('utmSourcesCode', JSON.stringify({ ...utmSourcesCode, expiryDate }))
      }

      return utmSourcesCode
    } catch {
      return {}
    }
  }

  const handleSubmit = async () => {
    try {
      setIsShowLoading(true)

      const utmSourcesCode = getQueryStrings()
      const trimmedEmail = email.trim()

      if (hasMicrosoftDomain(trimmedEmail)) {
        await createAccountOtpSanar(trimmedEmail, partnerSlug, utmSourcesCode)

        history.push(`/login/${partnerSlug}/otp`)
        setIsShowLoading(false)
        return
      }

      await createAccountSanar(trimmedEmail, partnerSlug, utmSourcesCode)

      history.push(`/login/${partnerSlug}/send-email`)
    } catch (err) {
      const message = err?.response?.data?.error?.message

      setIsShowLoading(false)
      if (message === alreadyHasContractMessage) return history.push(`/login/${partnerSlug}/have-contract`)

      toast.error(message || 'Ocorreu um erro inesperado')
    }
    setIsShowLoading(false)
  }

  return [handleSubmit, partnerSlug]
}
