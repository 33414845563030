import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Person } from '@material-ui/icons'
import { Button, PortalModal, TextErrorInput, showToast, theme } from '@provi/provi-components'
import { withFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { Checked } from '../../assets/svg/checked'
import { CloseButton } from '../../assets/svg/close-button'
import { LogoPrincipia } from '../../assets/svg/logo-principia'
import { Unchecked } from '../../assets/svg/unchecked'
import Checkbox from '../../components/checkbox'
import { MesadaProviSlug } from '../../constants'
import { history } from '../../navigation/history'
import { signIn, signInOTP, signUp } from '../../services'
import { hasMicrosoftDomain, transformObjectIntoQueryParams } from '../../utils'
import {
  Bold,
  ContainerLogo,
  ContainerLogoname,
  ContainerText,
  ExtraDataText,
  ExtraDataTitle,
  Footer,
  Form,
  ItemCheck,
  ModalText,
  ModalTitle,
  PartnerImage,
  PipeSeparator,
  SubTitle,
  TextNamePartner,
  Title,
  WrapperExtraData,
  WrapperIcon,
  partnerLogos,
} from '../style'
import { useIntroduction } from './hooks'

const WrapperFormik = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setScreen,
    setTypeSign,
    partner,
    setIsShowLoading,
    isValid,
    setValidateTerms,
    setPartnerFullName,
  } = props
  const [title, texts, selectCheckbox, namePartner, checked, handleCheckedChange, modalRef, redirectTimeLeft] = useIntroduction(
    setIsShowLoading,
    setValidateTerms,
    null,
    setPartnerFullName,
    props.match.params.campanha
  )

  const shouldDisableButton = !checked || !(isValid && texts.filter((item) => !item.status).length === 0)

  useEffect(() => {
    const params = new URLSearchParams(props.history.location.search)
    const email = params.get('email')
    if (email) handleChange('email')(email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const partnerInfo = useMemo(() => {
    if (namePartner === 'Sanar UP') return <PartnerImage src={partnerLogos.SanarUPLogo} />

    return <TextNamePartner style={{ marginLeft: '3px' }}>{namePartner}</TextNamePartner>
  }, [namePartner])

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      return !checked ? event.preventDefault() : handleSubmit
    }
  }

  return (
    <>
      <PortalModal ref={modalRef} width="496px">
        <ContainerText>
          <ModalTitle>
            Você será redirecionado <CloseButton onClick={() => modalRef.current.closeModalFn()} />
          </ModalTitle>
          <ModalText>
            Vamos abrir o site da Principia em {redirectTimeLeft} segundo{redirectTimeLeft !== 1 && 's'}. É rapidinho!
          </ModalText>
        </ContainerText>
      </PortalModal>

      {namePartner ? (
        <ContainerLogo>
          <LogoPrincipia />
          <ContainerLogoname>
            <PipeSeparator />
            <TextNamePartner style={{ display: 'flex' }}>{partnerInfo}</TextNamePartner>
          </ContainerLogoname>
        </ContainerLogo>
      ) : (
        <LogoPrincipia />
      )}
      <ContainerText>
        {namePartner ? (
          <Title color={partner === MesadaProviSlug ? theme.colors.blue600 : theme.colors.pink900} size={20}>
            {partner !== MesadaProviSlug
              ? `Principia e ${namePartner} se juntaram para que você alcance seus objetivos profissionais através de crédito
            descomplicado.`
              : 'Imagina se você pudesse ter uma mesada todo mês para não se preocupar com as contas durante a faculdade. Agora você pode!'}
          </Title>
        ) : (
          <Title>Bem-vindo(a) à Principia.</Title>
        )}

        {partner && <SubTitle margin>Sua primeira vez aqui?</SubTitle>}
        <Bold>{partner ? 'Digite o seu melhor email!' : 'Digite abaixo o seu email de cadastro:'}</Bold>
      </ContainerText>
      <Form onSubmit={handleSubmit} onKeyPress={(event) => handleOnKeyPress(event)}>
        <FormControl>
          <Input
            autoFocus
            placeholder="Escreva aqui seu email"
            inputProps={{ style: { color: 'white' } }}
            value={values.email}
            type="email"
            onChange={handleChange('email')}
            startAdornment={
              <InputAdornment position="start">
                <Person style={{ color: 'white' }} />
              </InputAdornment>
            }
          />
          {errors.email && <TextErrorInput>{errors.email}</TextErrorInput>}
        </FormControl>

        {namePartner && (
          <FormControl>
            <Checkbox
              checked={checked}
              onChange={handleCheckedChange}
              text={
                <>
                  <span>Li e estou de acordo com os </span>
                  <a href="https://provi.com.br/termos-de-uso" target="_blank" rel="noopener noreferrer">
                    Termos de uso e Política de Privacidade
                  </a>
                  <span> da Principia </span>
                </>
              }
            />
          </FormControl>
        )}
      </Form>

      {title && texts && texts.length > 0 && (
        <WrapperExtraData>
          <ExtraDataTitle>{title}</ExtraDataTitle>
          {texts.map((item) => (
            <ItemCheck key={item.id} onClick={() => selectCheckbox(item)}>
              <WrapperIcon>{!item.status ? <Unchecked /> : <Checked />}</WrapperIcon>
              <ExtraDataText key={item} dangerouslySetInnerHTML={{ __html: item.text }} />
            </ItemCheck>
          ))}
        </WrapperExtraData>
      )}
      <Footer>
        {partner ? (
          <Button
            text="Criar sua conta"
            onClick={handleSubmit}
            disabled={shouldDisableButton}
            backgroundDisabled={shouldDisableButton}
            textLink={{
              text: 'Já tenho uma conta',
              color: 'white',
              onClick: () => {
                setTypeSign('signIn')
                setScreen('signIn')
              },
            }}
          />
        ) : (
          <Button backgroundDisabled={shouldDisableButton} disabled={!isValid} text="Avançar" onClick={handleSubmit} />
        )}
      </Footer>
    </>
  )
}

export const FormFormik = withFormik({
  validateOnChange: true,
  enableReinitialize: true,
  isInitialValid: false,
  mapPropsToValues: (props) => ({ email: props.emailLocal }),
  validationSchema: () => {
    const schema = {
      email: Yup.string().email('Digite um email válido').required('Preencha o campo Email'),
    }
    return Yup.object().shape(schema)
  },
  handleSubmit: async ({ email }, { props }) => {
    if (props.validateTerms) {
      props.setTypeSign('signUp')
      props.setEmail(email)
      props.setIsShowLoading(true)
      const { partner, campanha: campaign, isISA, utmSourcesCode } = props

      try {
        if (!hasMicrosoftDomain(email)) {
          let response
          if (props.partner) {
            response = await signUp({ email, partner, campaign, isISA, utmSourcesCode })
            props.setTypeModal('dontReceiveEmail')
            if (response.status === 200) {
              props.setScreen('sendEmail')
            }
          } else {
            props.setTypeSign('signIn')
            response = await signIn({ email, partner, campaign, isISA })
            props.setTypeModal('dontReceiveEmail')
          }
          if (response.status === 204) {
            props.setScreen('resendingEmail')
          }
        } else {
          if (props.partner) {
            const { error } = await signUp({ email, partner, campaign, isISA, OTP: true, utmSourcesCode })

            !error &&
              history.push({
                pathname: '/verificacao',
                search: transformObjectIntoQueryParams({
                  parceiro: partner,
                  email,
                  isa: isISA,
                  campanha: campaign,
                  ...utmSourcesCode,
                }),
              })
          } else {
            const { error } = await signInOTP({ email, campaignSlug: campaign, isISA, partnerSlug: partner })

            history.push({
              pathname: error ? '/email-nao-encontrado' : '/verificacao',
              search: transformObjectIntoQueryParams({ parceiro: partner, email, isa: isISA, campanha: campaign }),
            })
          }
        }
      } catch (err) {
        if (err.response) {
          const errorMessage = err.response.data.error.message.split(' ')[0]
          if (['Remova', 'Não', 'O'].includes(errorMessage) || err.response.data.error.message.includes('ISA')) {
            showToast(err.response.data.error.message || 'Ocorreu um erro inesperado')
            return false
          }
        }

        if (!hasMicrosoftDomain(email)) {
          props.setScreen('searchSignup')
        } else {
          history.push('/nao-encontrado')
        }
      } finally {
        props.setIsShowLoading(false)
      }
    }
  },
})(WrapperFormik)
