import { toast } from 'react-toastify'
import { useState } from 'react'
import { history } from '../../../navigation/history'
import { createAccountOtpSanar, createAccountSanar } from '../../../services'
import { hasMicrosoftDomain } from '../../../utils'
import { useRouteValidation } from '../useRouteValidation'

export const useResendEmail = ({ email, setIsShowLoading, setRevalidateOtp }) => {
  const [partnetSlug, setPartnerSlug] = useState('')

  useRouteValidation(setPartnerSlug)

  const handleSubmit = async () => {
    setIsShowLoading(true)
    try {
      const trimmedEmail = email.trim()

      if (hasMicrosoftDomain(trimmedEmail)) {
        await createAccountOtpSanar(trimmedEmail, partnetSlug)
        setRevalidateOtp(true)
        history.push(`/login/${partnetSlug}/otp`)
        setIsShowLoading(false)
        return
      }
      await createAccountSanar(trimmedEmail, partnetSlug)

      history.push(`/login/${partnetSlug}/email-not-found`)
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.error.message.split(' ')[0]
        if (['Remova', 'Não'].includes(errorMessage) || err.response.data.error.message.includes('ISA')) {
          toast.error(err.response.data.error.message || 'Ocorreu um erro inesperado')
          return false
        }
      }
    }
    setIsShowLoading(false)
  }

  return [handleSubmit]
}
