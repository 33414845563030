import React, { useContext } from 'react'
import { theme } from '@provi/provi-components'

import { TemplateContext } from '../../components/template/context'
import { HeaderWrapper, Text } from './styles'

export const Header = () => {
  const { partnerFullName } = useContext(TemplateContext)

  return (
    <HeaderWrapper>
      <Text bold>Provi {partnerFullName && ` | ${partnerFullName}`}</Text>
      <Text bold color={theme.colors.pink900}>
        Transformando carreiras
      </Text>
    </HeaderWrapper>
  )
}
