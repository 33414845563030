import styled from 'styled-components'

export const DarkSideIllustrationWrapper = styled.div`
  display: flex;
  align-items: right;
  justify-content: end;
  position: absolute;
  bottom: 0;
  right: 0;
`
