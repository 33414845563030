import { useState } from 'react'
import * as Yup from 'yup'

import { signInUsingCPF } from '../../../services'

export const useEmailNotFound = (setIsShowLoading, setEmailId, setEmail, history, searchProps) => {
  const [cpf, setCpf] = useState('')
  const [error, setError] = useState('')

  const onChange = ({ target: { value } }) => {
    setCpf(value)

    const schema = Yup.object().shape({
      cpf: Yup.string()
        .required('CPF é obrigatório')
        .max(14, 'Seu CPF tem que ter 11 caracteres')
        .min(14, 'Seu CPF tem que ter 11 caracteres'),
    })

    if (!schema.isValidSync({ cpf: value })) {
      try {
        schema.validateSync({ cpf: value })
      } catch (err) {
        setError(err.errors[0])
      }
    } else {
      setError('')
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setIsShowLoading(true)

    const { error: signInError, response } = await signInUsingCPF(cpf)

    if (signInError) {
      history.push({
        pathname: '/cpf-nao-encontrado',
        search: searchProps,
      })
    } else {
      setEmailId(response.EmailId)
      setEmail(response.email)

      history.push({
        pathname: '/confirmacao-email',
        search: searchProps,
      })
    }

    setIsShowLoading(false)
  }

  return [cpf, onChange, onSubmit, error]
}
