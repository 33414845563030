import { useEffect, useRef, useState } from 'react'
import { getSlugPartner } from '../../utils'
import { getSlugInfo } from '../../services'
import { history } from '../../navigation/history'

export const useIntroduction = (setIsShowLoading, setValidateTerms, validateTerms, setPartnerFullName, campaign) => {
  const [title, setTitle] = useState()
  const [namePartner, setNamePartner] = useState()
  const [texts, setTexts] = useState([])
  const [checked, setChecked] = useState(false)
  const [redirectTimeLeft, setRedirectTimeLeft] = useState(5)

  const modalRef = useRef()

  const openModal = () => {
    modalRef.current.openModalFn()
  }

  const handleCheckedChange = () => {
    setChecked(!checked)
  }

  useEffect(() => {
    setValidateTerms(texts.filter((item) => !item.status).length === 0)
  }, [validateTerms, texts, setValidateTerms])

  useEffect(() => {
    const getData = async (partner) => {
      try {
        const path = window.location.pathname
        const isISA = path.includes('/isa/')
        setIsShowLoading(true)
        const { data: partnerInfo } = await getSlugInfo(partner, isISA)

        if (partnerInfo?.data?.urlRedirect?.length) {
          const urlRedirect = campaign ? `${partnerInfo.data.urlRedirect}?campaign=${campaign}` : partnerInfo.data.urlRedirect

          openModal()

          const redirectTimeLeftInterval = setInterval(() => {
            setRedirectTimeLeft((prevState) => prevState - 1)
          }, 1000)

          setTimeout(() => {
            clearInterval(redirectTimeLeftInterval)
            window.location.href = urlRedirect
          }, redirectTimeLeft * 1000)
        }
        setNamePartner(partnerInfo.data.name)
        setPartnerFullName(partnerInfo.data.name)

        const extraInfo = partnerInfo.data.extraLoginInfo

        setTitle(extraInfo[0])
        extraInfo.shift()
        const array = extraInfo.map((item, index) => ({
          id: index,
          text: item,
          status: false,
        }))
        setTexts(array)
      } catch (err) {
        console.error(err)
        setIsShowLoading(false)
      } finally {
        setIsShowLoading(false)
      }
    }
    const partnerSlug = getSlugPartner()

    if (['sanar'].includes(partnerSlug)) {
      history.push('login/sanar')
    }

    if (partnerSlug) {
      getData(partnerSlug)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsShowLoading, setPartnerFullName])

  const selectCheckbox = (item) => {
    const arrayNew = texts.map((option) => {
      if (item.status) {
        if (item.id === option.id) {
          item.status = false
        }
      } else {
        if (item.id === option.id) {
          item.status = true
        }
      }
      return option
    })
    setTexts(arrayNew)
  }

  return [title, texts, selectCheckbox, namePartner, checked, handleCheckedChange, modalRef, redirectTimeLeft]
}
